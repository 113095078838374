import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {Fade} from 'react-awesome-reveal';
import Callback from '../Callback';
import './index.css';
import Faq from '../Faq';

const faqData = [
  {
    question: "1. What is the best way to travel around Switzerland?",
    answer: `Switzerland has one of the best public transport systems in the world. Trains, buses, and boats are well-connected and punctual. Consider buying a Swiss Travel Pass for unlimited travel.`
  },
  {
    question: "2. Is Switzerland expensive for tourists?",
    answer: `Yes, Switzerland is known for being expensive. However, you can save by using public transport, staying in budget accommodations, and enjoying free activities like hiking and sightseeing.`
  },
  {
    question: "3. Do I need a visa to visit Switzerland?",
    answer: `If you are from the EU, USA, Canada, or certain other countries, you can visit Switzerland visa-free for up to 90 days. Check Switzerland’s visa requirements for your country before planning your trip.`
  },
  {
    question: "4. What currency is used in Switzerland?",
    answer: `Switzerland uses the Swiss Franc (CHF). Credit cards are widely accepted, but carrying some cash is recommended for small purchases.`
  },
  {
    question: "5. What are some must-try Swiss dishes?",
    answer: `Must-try Swiss dishes include:<br>
             - <strong>Fondue</strong>: Melted cheese served with bread.<br>
             - <strong>Rösti</strong>: Crispy grated potatoes.<br>
             - <strong>Chocolate</strong>: Swiss chocolates are world-famous!<br>
             - <strong>Raclette</strong>: Melted cheese served with pickles and potatoes.`
  }
];

const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/switz%2Fswitz-7.webp?alt=media&token=9b9db35f-4362-4d2f-b950-204b03d77b04",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/switz%2Fswitz-2.webp?alt=media&token=47a6694f-c3ac-44fb-b8b5-307842e0927b",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/switz%2Fswitz-1.webp?alt=media&token=5b02273a-d438-4303-9a8e-0c3bd9f37079",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/switz%2Fswitz-3.webp?alt=media&token=e64c9f81-b493-4a18-b840-64918f2e6a2e",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/switz%2Fswitz-6.webp?alt=media&token=a77e6d7b-35aa-47dc-80df-96da1ea1a285",
]

const DestinationSwitzerland = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const himachalPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('switzerland')
      );

    return (
        <div>
        <HelmetProvider>
        <Helmet>
            <title>Explore Switzerland: Travel Guide and Attractions</title>
            <meta
                name="description"
                content="Plan your dream trip to Switzerland with our comprehensive travel guide. Discover top attractions, must-try dishes, travel tips, and visa formalities. Start your Swiss adventure today!"
                />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <Callback contactNumber="+91 9845090014"/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 switz-container mt-5'>
                     <Fade>
                        <h1>Discover Switzerland - The Land of Alpine Wonders</h1>
                        <p>Switzerland, nestled in the heart of Europe, is a dream destination for travelers. From breathtaking alpine landscapes to picturesque towns and vibrant cities, Switzerland offers an experience like no other. Whether you're seeking adventure, tranquility, or cultural exploration, this stunning country has something for everyone.</p>
                        <h2>Best Times to Visit Switzerland</h2>
                        <ul>
                          <li><b>Summer (June-August):</b> Ideal for hiking, lake activities, and city tours.</li>
                          <li><b>Winter (December-February):</b> Perfect for skiing and snow adventures in the Alps.</li>
                        </ul>
                        <h2>Visa Formalities</h2>
                        <p>Switzerland is part of the Schengen Area, allowing visitors from many countries to stay visa-free for up to 90 days within a 180-day period. Travelers requiring a visa can apply for a Schengen Visa.</p>
                        <p>Visit the official Swiss <a href='https://www.swiss-visa.ch/ivis2/#/i210-select-country' target='__blank'>Visa Application website</a> to check eligibility and apply online.</p>
                        <h2>Where to Stay in Switzerland</h2>
                        <ul>
                          <li><b>Luxury Hotels:</b> The Dolder Grand (Zurich), Kulm Hotel (St. Moritz).</li>
                          <li><b>Mid-range Options:</b> Hotel des Balances (Lucerne), Bellevue Palace (Bern).</li>
                          <li><b>Budget-Friendly:</b> Backpacker’s Villa Sonnenhof (Interlaken), HI Hostel (Geneva).</li>
                        </ul>
                        <h2>Top Attractions in Switzerland</h2>
                        <h3>1. The Swiss Alps</h3>
                        <ul>
                          <li>Experience world-class skiing, snowboarding, and hiking.</li>
                          <li><b>Must-Visit Spots:</b> Zermatt (Matterhorn), Jungfraujoch, and St. Moritz.</li>
                        </ul>
                        <h3>2. Lake Geneva</h3>
                        <ul>
                          <li>Relax by the serene waters of Lake Geneva, surrounded by vineyards and the French Alps.</li>
                          <li><b>Activities:</b> Boat tours, wine tasting, and exploring Geneva city.</li>
                        </ul>
                        <h3>3. Zurich</h3>
                        <ul>
                          <li>Discover Switzerland’s largest city, blending modern charm with historical heritage.</li>
                          <li><b>Top Sites:</b> Old Town, Bahnhofstrasse, and the Swiss National Museum.</li>
                        </ul>
                        <h3>4. Interlaken</h3>
                        <ul>
                          <li>The adventure capital of Switzerland, perfect for paragliding, hiking, and boat cruises.</li>
                          <li><b>Don’t Miss:</b> Harder Kulm and the nearby Lauterbrunnen Valley.</li>
                        </ul>
                        <h3>5. Lucerne</h3>
                        <ul>
                          <li>A storybook town with a stunning lake and iconic Chapel Bridge.</li>
                          <li><b>Nearby Highlight:</b> Mount Pilatus.</li>
                        </ul>
                        <h2>Frequently Asked Questions(FAQ's)</h2>
                        <Faq faqData={faqData}/>
                        </Fade>
                    </div>
                </div>
            </div>
                <p className='switz-destination-head mb-3 mt-5'>View Our Switzerland Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {himachalPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </div>
    );
}

export default DestinationSwitzerland;
