import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { FaHotel } from "react-icons/fa6";
import { LuPackagePlus } from "react-icons/lu";
import { MdFamilyRestroom } from "react-icons/md";
import KeralaOffer from "../KeralaOffer";
import Callback from "../Callback";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {Fade} from 'react-awesome-reveal';
import Faq from '../Faq';
import './index.css';

const faqData = [
  {
    question: "1. What is the best time to visit Kerala?",
    answer: `The best time to visit Kerala is during the winter months, from October to March. The pleasant weather during this time makes it ideal for sightseeing, exploring backwaters, and enjoying the beaches.`
  },
  {
    question: "2. How can I experience Kerala's backwaters?",
    answer: `Kerala’s backwaters are best experienced through houseboat cruises, especially in Alleppey and Kumarakom. We offer customized houseboat packages that let you explore the serene waters, traditional Kerala cuisine, and breathtaking views.`
  },
  {
    question: "3. What are the must-visit destinations in Kerala?",
    answer: `Some must-visit destinations in Kerala include:<br>
             <strong>Munnar:</strong> Famous for its tea plantations.<br>
             <strong>Alleppey:</strong> Known for its backwaters.<br>
             <strong>Kochi:</strong> A hub of cultural heritage.<br>
             <strong>Thekkady:</strong> Home to rich wildlife.<br>
             <strong>Kovalam:</strong> Renowned for its beaches.<br>
             <strong>Wayanad:</strong> Offers lush greenery and serene landscapes.`
  },
  {
    question: "4. Is Kerala a safe destination for solo travelers?",
    answer: `Yes, Kerala is considered a safe destination for solo travelers. The people are welcoming, and we ensure our tour packages prioritize safety and comfort for solo travelers. You can rely on Travel Unbounded for guided experiences and local insights.`
  },
  {
    question: "5. What should I pack for a trip to Kerala?",
    answer: `Essentials to pack include:<br>
             - Lightweight clothing suitable for the tropical climate.<br>
             - Comfortable shoes for walking and exploration.<br>
             - Sunblock, hat, and sunglasses.<br>
             - Rain gear if visiting during the monsoon season.<br>
             - Modest attire for temple visits to respect local customs.`
  },
  {
    question: "6. Can I customize my Kerala travel itinerary?",
    answer: `Absolutely! We offer customizable travel packages to suit your preferences. Whether you’re looking for adventure, relaxation, cultural experiences, or wellness tourism, our team can tailor the itinerary just for you.`
  },
  {
    question: "7. What traditional dishes should I try in Kerala?",
    answer: `Kerala is famous for its rich cuisine. Some must-try dishes include:<br>
             - <strong>Appam with Stew</strong>: A popular breakfast dish.<br>
             - <strong>Puttu and Kadala Curry</strong>: A unique combination of steamed rice cakes and curry.<br>
             - <strong>Kerala Sadya</strong>: A traditional vegetarian feast.<br>
             - <strong>Malabar Parotta</strong>: A flaky, layered flatbread.<br>
             - <strong>Seafood dishes</strong>: Fresh and flavorful options, especially along the coast.`
  },
  {
    question: "8. Do you offer packages for Kerala Ayurveda and Wellness Retreats?",
    answer: `Yes, we provide packages that include authentic Ayurvedic therapies and wellness retreats to rejuvenate your body and mind. Our options range from massages and spa treatments to detox and rejuvenation programs with experienced professionals.`
  },
  {
    question: "9. What is the ideal duration for a Kerala trip?",
    answer: `An ideal Kerala trip can be anywhere between 5 to 10 days, depending on your interests. We recommend a minimum of 7 days to cover major attractions, including backwaters, hills, and beaches.`
  },
  {
    question: "10. How can I book a Kerala tour package with Travel Unbounded?",
    answer: `You can visit our website (<a href="https://travelunbounded.com/kerala">www.travelunbounded.com/kerala</a>), browse available packages, and contact us directly through our online form or provided contact details. Our travel experts are ready to assist you in planning the perfect Kerala experience.`
  }
];


const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kerala%2F6918f46e-2d26-42d4-abb8-68587915c07b.webp?alt=media&token=3233d0fc-bb89-404c-8b0c-974d4a44c90b",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kerala%2Frice-field-8295148_1920.webp?alt=media&token=c5988df0-1d55-4cff-a1d0-094d5e16396d",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kerala%2Frice-field-1866498_1920.webp?alt=media&token=663fc3b5-6a23-4d39-9b7f-4ed29568ca15",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kerala%2Fsunset-1139293_1920.webp?alt=media&token=829f892c-fcde-47ff-8822-854e2b2e7e50",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Iceland%2Fpexels-appu-ss-1963001871-28901908-2.webp?alt=media&token=a7e71bd8-ac7c-4b07-b632-c3b6ac5fda7c",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Iceland%2Fpexels-deepak-murali-437548-1273246-2.webp?alt=media&token=4bf5c209-1b41-4d69-81ea-6e1626746c67",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kerala%2Friver-5953659_1920.webp?alt=media&token=78a3a48f-f4c8-4067-a560-63212fa24fe8"
]

const DestinationKerala = () => {
    const [packages, setPackages] = useState([]);
    const [showModal, setShowModal] = useState(true);

    const handleCloseModal = () => {
      setShowModal(false);
    };

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const keralaPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('kerala')
      );

      const sortedPackages = keralaPackages.sort((a, b) => a.id - b.id);
    
    return (
        <>
         <HelmetProvider>
         <Helmet>
            <title>Kerala Tour Packages | Houseboat & Backwater Tours – Travel Unbounded.</title>
            <meta
                name="description"
                content="Explore Kerala's scenic backwaters, lush tea gardens, and rich cultural heritage with customized travel packages from Travel Unbounded."
                />
            </Helmet>
            <Navbar/>
            <HomeSlider
             images={images}
             showOverlayText={true}
            overlayTitle="Colourful Kerala"
            overlayPrice="INR 13000/-"
            originalPrice="INR 16250/-"
            buttonText="Limited Offer"
             />
             <Callback contactNumber="+91 9141001434"/>
            {showModal && (
                <div className="modal-overlay-offer">
                  <div className="modal-content-offer">
                    <div className="modal-header-offer">
                      <button onClick={handleCloseModal} className="close-button">✖</button>
                      <p>Biggest Price Drop Sale is Live</p>
                      <h3 className='blinking-price-offer'>Flat 25% OFF Book Now</h3>
                    </div>
                    <div className="modal-image" style={{ backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/enquire%2Foffer-1.webp?alt=media&token=a4cb3585-679a-416e-ac88-ec8b49b19ff4)' }}>
                      <div className="modal-facilities">
                        <div className="facility">
                          <FaHotel size={30} className="facility-icon"/>
                          <p>Handpicked, High-Quality Hotels</p>
                        </div>
                        <div className="facility">
                        <LuPackagePlus size={30} className="facility-icon"/>
                          <p>Perfectly Tailored Itineraries</p>
                        </div>
                        <div className="facility">
                        <MdFamilyRestroom size={30} className="facility-icon"/>
                          <p>Unforgettable Family Vacations</p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-buttons">
                      <button onClick={handleCloseModal} className="dismiss-button">Dismiss</button>
                      <a href="https://wa.me/917483129148?text=Hi" target="__blank" aria-label="WhatsApp">
                        <button className="chat-now-button">Chat Now</button>
                      </a>
                    </div>
                  </div>
                </div>
              )}
             <KeralaOffer/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 kerala-container mt-5'>
                      <Fade>
                      <Link to='/package/leisure/Colourful-kerala' className='link'><h1 className='blinking-price'>Discover the Magic of Kerala: A Comprehensive Guide to “God’s Own Country”</h1> </Link>
                      <p>Kerala is a breathtaking state in southern India, known for its lush landscapes, rich cultural heritage, and exceptional quality of life. Whether you're looking for a peaceful getaway or a cultural adventure, Kerala offers a unique blend of natural beauty, modern amenities, and traditional experiences, making it an ideal destination for travelers and a remarkable place to live.</p>
                      <h2>Geography of Kerala: Where Nature Meets Culture</h2>
                      <p>Kerala’s geography is characterized by verdant hills, serene backwaters, and stunning beaches. With the Western Ghats on one side and the Arabian Sea on the other, the state offers a blend of mountain ranges, dense forests, and sandy shores, making it one of the most diverse and scenic regions in India.</p>
                      <h2>Airports in Kerala: Gateway to the World</h2>
                      <p>Kerala is well-connected to the rest of the world through two major international airports, ensuring easy access for both domestic and international travelers.</p>
                      <ul>
                        <li><b>Cochin International Airport (COK):</b><br/>The largest and busiest airport in Kerala, Cochin International Airport is the first in the world to run entirely on solar energy. It serves as a gateway to central and northern Kerala and offers an array of international flights, linking the state to key destinations around the world.</li>
                        <li><b>Trivandrum International Airport (TRV):</b><br/>Situated in the southern part of Kerala, Trivandrum International Airport connects Thiruvananthapuram and its surrounding regions to various parts of the world. The airport has been recently upgraded to enhance the passenger experience and offers flights to Europe, the Middle East, and other global destinations.</li>
                      </ul>
                      <h2>Climate of Kerala: Tropical Paradise</h2>
                      <p>Kerala enjoys a tropical climate, with a distinct monsoon season from June to September, bringing heavy rains that nourish the state's abundant flora and fauna. The weather is generally warm and humid, making it a year-round destination for nature lovers and adventure seekers.</p>
                      <h2>Culture of Kerala: A Mosaic of Traditions</h2>
                      <p>The rich cultural tapestry of Kerala is woven from centuries-old traditions influenced by various communities and religions. The state is renowned for its classical dance forms, including Kathakali and Mohiniyattam, and celebrates vibrant festivals like Onam and Vishu. The culinary delights, traditional arts, and handicrafts add to Kerala’s cultural richness, offering an immersive experience for visitors.</p>
                      <h2>Visa Information for Foreign Nationals</h2>
                      <p>Visitors from abroad can check the specific <a href='https://indianvisaonline.gov.in/evisa/tvoa.html' target='__blank'>visa requirements</a> for their country. Ensure you have the correct documentation for a smooth entry into Kerala.</p>
                      <h2>Unique Kerala Experiences: Exploring the Top Destinations</h2>
                      <p>Kerala is packed with a variety of experiences for every type of traveler. Here are some must-visit destinations that capture the essence of this magical state.</p>
                      <h3>1. Backwater Experiences in Kerala</h3>
                      <ul>
                        <li><b>Alleppey (Alappuzha) Backwaters:</b><br/>Known as the “Venice of the East,” Alleppey offers houseboat cruises that glide through serene backwaters, providing glimpses of traditional Kerala villages and lush landscapes.</li>
                        <li><b>Kumarakom Backwaters:</b><br/>Another iconic backwater destination, Kumarakom is famous for its bird sanctuaries and luxury houseboat experiences, making it a perfect spot for relaxation and nature exploration.</li>
                      </ul>
                      <h3>2. Hill Stations of Kerala</h3>
                      <ul>
                        <li><b>Munnar:</b><br/>Famed for its sprawling tea plantations, rolling hills, and cool climate, Munnar is a haven for nature lovers. Key attractions include Eravikulam National Park, home to the Nilgiri Tahr, Anamudi Peak, and Mattupetty Dam.</li>
                        <li><b>Wayanad:</b><br/>A tranquil escape into nature, Wayanad offers waterfalls, wildlife sanctuaries, and ancient Edakkal Caves. Explore the picturesque Pookode Lake and visit the Wayanad Wildlife Sanctuary for a true wilderness experience.</li>
                      </ul>
                      <h3>3. Pristine Beaches of Kerala</h3>
                      <ul>
                        <li><b>Kovalam Beach:</b><br/>A popular destination for both locals and tourists, Kovalam boasts crescent-shaped beaches like Lighthouse Beach and Hawa Beach, offering stunning sunsets and water activities.</li>
                        <li><b>Varkala Beach:</b><br/>Known for its striking cliff-top views and Papanasam Beach, Varkala holds spiritual significance and provides a peaceful retreat by the sea.</li>
                        <li><b>Cherai Beach:</b><br/>Located near Kochi, Cherai Beach offers a quieter, more relaxing atmosphere and is perfect for a swim or a leisurely stroll.</li>
                      </ul>
                      <h3>4. Wildlife Sanctuaries in Kerala</h3>
                      <ul>
                        <li><b>Periyar Wildlife Sanctuary, Thekkady:</b><br/>Nestled in the hills of Thekkady, Periyar is one of the most famous wildlife sanctuaries in India, home to elephants, tigers, and other wildlife. Take a boat ride on Periyar Lake or join a nature walk through the sanctuary.</li>
                        <li><b>Silent Valley National Park:</b><br/>This remote park in the Western Ghats is a biodiversity hotspot, sheltering a wide variety of rare and endangered species.</li>
                      </ul>
                      <h3>5. Cultural and Historical Sites in Kerala</h3>
                      <ul>
                        <li><b>Fort Kochi:</b><br/>Steeped in history, Fort Kochi showcases a mix of Portuguese, Dutch, and British colonial architecture. Explore the Chinese fishing nets, visit St. Francis Church, and experience the Kochi-Muziris Biennale, India’s largest contemporary art exhibition.</li>
                        <li><b>Padmanabhaswamy Temple:</b><br/>Located in the capital city of Thiruvananthapuram, this temple is renowned for its architectural splendor and religious significance, drawing devotees and visitors alike.</li>
                      </ul>
                      <h3>6. Ayurvedic Wellness in Kerala</h3>
                      <ul>
                        <li><b>Kumarakom & Varkala:</b><br/>Kerala is the birthplace of Ayurveda, and many visitors come here for rejuvenating Ayurvedic treatments. Renowned wellness centers in Kumarakom and Varkala offer therapies that promise relaxation and healing.</li>
                      </ul>
                      <h3>7. Adventure Activities in Kerala</h3>
                      <ul>
                        <li><b>Athirappilly Waterfalls:</b><br/>Known as the “Niagara of India,” Athirappilly is the largest waterfall in Kerala. It’s a popular spot for trekking and nature photography.</li>
                        <li><b>Paragliding in Vagamon:</b><br/>For thrill-seekers, Vagamon offers the perfect conditions for paragliding, giving adventurers a bird’s-eye view of Kerala’s stunning landscapes.</li>
                      </ul>
                      <h3>8. Local Markets and Crafts of Kerala</h3>
                      <ul>
                        <li><b>Kochi’s Spice Market:</b><br/>Kerala is famous for its spices, and a visit to Kochi’s spice markets offers a sensory delight. You can buy pepper, cardamom, cinnamon, and other spices, along with textiles and handicrafts.</li>
                        <li><b>Kerala Kalamandalam:</b><br/>Located in Thrissur, the Kerala Kalamandalam is the premier center for learning traditional performing arts like Kathakali and Mohiniyattam.</li>
                      </ul>
                      <h2>Frequently Asked Questions(FAQ's)</h2>
                      <Faq faqData={faqData}/>
                      </Fade>
                    </div>
                </div>
            </div>
                <p className='kerala-destination-head mb-3 mt-5'>View Kerala travel packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {sortedPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <div className="image-container-kerala">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        {pkg.recommend && (
                          <span className="recommend-tag">{pkg.tag}</span>
                        )}
                        </div>
                        <div>
                            <p className="title-list mb-3">{pkg.destinationHead}</p>
                            <p className="title-dates-list bold mb-3">{pkg.noOfDays}</p>
                            {pkg.isOffer ? (
                              <p className='mt-3 package-template-price title-rate-list'>
                                <span style={{ paddingLeft: '10px' }}>
                                  <b><span className="blinking-price">{pkg.newCost}</span></b>
                                  <span className="linethrough-price"> {pkg.originalPrice}</span>
                                  <span style={{ marginLeft: '5px' }}>PP</span>
                                </span>
                              </p>
                            ) : (
                              <p className="title-rate-list bold mb-3">{pkg.cost}</p>
                            )}
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </>
    );
}

export default DestinationKerala;
