import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Faq from '../Faq';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {Fade} from 'react-awesome-reveal';
import Callback from '../Callback';
import './index.css';

const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kabini%2Fkabini-5.webp?alt=media&token=60eb30c9-02d2-4dc1-8f98-055c9452907e",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kabini%2Fkabini-1.webp?alt=media&token=a0672dfb-e543-4c66-8db4-6b043a5a056f",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kabini%2Fkabini-2.webp?alt=media&token=ebd4cbaf-07ef-4a61-9e0d-4272aad14f61",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kabini%2Fkabini-3.webp?alt=media&token=fdf1addf-7945-4d3c-b8db-2f49ef3b73c6"
]

const faqData = [
  {
    question: "1. What is the best time to visit Kabini?",
    answer: "The best time for wildlife sightings is during the dry season, from October to May, when animals congregate around water sources. However, Kabini offers different scenic views and experiences year-round."
  },
  {
    question: "2. How many days are ideal for a Kabini tour?",
    answer: "A 2-3 day stay is recommended to enjoy multiple safari sessions and absorb the tranquil environment."
  },
  {
    question: "3. Is Kabini family-friendly?",
    answer: "Absolutely. Kabini offers activities suitable for all ages, making it an ideal destination for family getaways and nature retreats."
  }
];

const DestinationKabini = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("WildlifePackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const keralaPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('kabini')
      );
    
    return (
        <>
         <HelmetProvider>
         <Helmet>
            <title>Kabini Wildlife Safari | Travel Unbounded - Best Kabini Tours & Packages</title>
            <meta
                name="description"
                content="Experience the wild beauty of Kabini with Travel Unbounded. Join our expertly guided safaris and discover Kabini’s elephants, tigers, and lush landscapes. Book your custom Kabini tour now!"
            />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <Callback contactNumber="+91 9845090014" />
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 kabini-container mt-5'>
                      <Fade>
                      <h1>Explore the Wild Beauty of Kabini with Travel Unbounded</h1>
                      <p>Dates: Flexible, <a href='/form'>Book Now</a> for Custom Packages</p>
                      <h2>Why Choose Kabini?</h2>
                      <p>Nestled in the heart of Karnataka, Kabini is a nature lover’s paradise, renowned for its stunning landscapes, dense forests, and remarkable wildlife. Whether you're an avid photographer, a wildlife enthusiast, or seeking a serene escape, Kabini offers a unique experience that connects you with nature.</p>
                      <h2>Top Reasons to Visit Kabini with Us</h2>
                      <ul>
                        <li><b>1. Breathtaking Wildlife</b><br/>Discover Kabini’s rich biodiversity, home to elephants, tigers, leopards, and the elusive black panther. Our guided safaris bring you up close to the marvels of the Kabini Wildlife Sanctuary.</li>
                        <li><b>2. Picturesque Landscapes</b><br/>From tranquil riversides to lush green forests, Kabini is a haven for those who appreciate natural beauty and peace. Experience the picturesque scenery, especially around the Kabini River.</li>
                        <li><b>3. Comfortable & Eco-Friendly Stays</b><br/>Enjoy eco-lodges and luxury accommodations that blend comfort with a commitment to sustainability, offering a perfect retreat in the heart of the wilderness.</li>
                        <li><b>4. Expert-Led Safaris and Tours</b><br/>Travel Unbounded provides expert-led safaris, ensuring safety and in-depth insights into the region’s ecology and wildlife behavior.</li>
                      </ul>
                      <h2>Highlights of Kabini Wildlife Safari</h2>
                      <ul>
                        <li><b>Safari Experience:</b> Jeep and boat safaris bring you close to the region’s most famous residents, including herds of elephants and, if lucky, a tiger or the elusive black panther.</li>
                        <li><b>Bird Watching:</b>  Kabini is home to over 250 bird species, making it a paradise for bird-watchers and photographers alike.</li>
                        <li><b>Scenic Boat Rides:</b> Glide through the Kabini River on a peaceful boat safari, providing unique views of the sanctuary from the water.</li>
                      </ul>
                      <h2>What Makes Travel Unbounded Unique?</h2>
                      <p>Our team of travel experts in Bangalore curates every detail of your Kabini safari, with local guides who have an in-depth understanding of the forest ecosystem. We ensure safety, comfort, and a rich learning experience for all our guests, allowing you to immerse yourself in Kabini’s wild beauty.</p>
                      <h2>Contact Us to Plan Your Kabini Adventure</h2>
                      <p>Ready to experience the wild side of Kabini? Reach out to us for personalized itineraries and special group offers. Our team at Travel Unbounded is here to make your Kabini safari an unforgettable journey into the heart of nature.</p>
                      <h2>Frequently Asked Questions (FAQs)</h2>
                      </Fade>
                      <Faq faqData={faqData}/>
                    </div>
                </div>
            </div>
                <p className='kabini-destination-head mb-3 mt-5'>View Our Kabini Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {keralaPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <p className="title-list mb-3">{pkg.destinationHead}</p>
                            <p className="title-dates-list bold mb-3">{pkg.noOfDays}</p>
                            <p className="title-rate-list bold mb-3">{pkg.cost}</p>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/wildlife/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </>
    );
}

export default DestinationKabini;
