import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {Fade} from 'react-awesome-reveal';
import Faq from '../Faq';
import Callback from '../Callback';
import "firebase/compat/firestore";
import './index.css';

const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/andaman%2Fandaman-2.webp?alt=media&token=6ce0949b-e664-4666-9844-d2d483fcc20b",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/andaman%2Fpexels-nabilnaidu-10794387.webp?alt=media&token=4a5480b2-6546-4b2a-a1b4-85d96d18b7fe",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/andaman%2Fandaman-3.webp?alt=media&token=0cf20d92-5064-44a8-a837-e396cc99ab67",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/andaman%2Fandaman-4.webp?alt=media&token=9d8b35d7-48b0-4254-8520-55a7ceaab895",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/andaman%2Fandaman-1.webp?alt=media&token=7b2cdfe0-db8e-49f0-9c89-ac4a0ec337ee"
]

const faqData = [
    {
      question: "1. What is the best time to visit the Andaman Islands?",
      answer: `The best time to visit the Andaman Islands is from October to May. During this period, the weather is pleasant, ideal for beach activities, water sports, and sightseeing.`
    },
    {
      question: "2. How can I reach the Andaman Islands?",
      answer: `You can reach the Andaman Islands via flights to Port Blair from major cities like Chennai, Kolkata, and Delhi. There are also cruise services available from Chennai, Kolkata, and Visakhapatnam for a more adventurous journey.`
    },
    {
      question: "3. Are the Andaman Islands safe for travelers?",
      answer: `Yes, the Andaman Islands are very safe for travelers, including solo tourists and families. The locals are friendly, and tourist areas are well-managed.`
    },
    {
      question: "4. What are the must-visit places in the Andaman Islands?",
      answer: `Some must-visit places include:<br>
               <strong>Radhanagar Beach (Havelock Island):</strong> Known for its pristine beauty.<br>
               <strong>Ross and Smith Islands:</strong> Famous for their natural sandbar.<br>
               <strong>Cellular Jail (Port Blair):</strong> A historic site.<br>
               <strong>Baratang Island:</strong> Features Limestone Caves and Mud Volcano.<br>
               <strong>Neil Island:</strong> Renowned for coral reefs and natural bridges.`
    },
    {
      question: "5. What activities can I do in the Andaman Islands?",
      answer: `Some popular activities include:<br>
               - <strong>Scuba Diving and Snorkeling:</strong> Explore the vibrant marine life.<br>
               - <strong>Glass-bottom boat rides:</strong> Enjoy views of underwater life.<br>
               - <strong>Exploring bioluminescent beaches:</strong> A magical nighttime experience.<br>
               - <strong>Trekking:</strong> Through lush forests and scenic trails.<br>
               - <strong>Visiting historic sites:</strong> Such as Cellular Jail.`
    },
    {
      question: "6. Do I need a permit to visit the Andaman Islands?",
      answer: `Indian citizens don’t need a permit. However, foreign nationals require a permit to enter, which can be obtained on arrival at Port Blair or through Indian embassies.`
    }
  ];
  


const DestinationAndaman = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const keralaPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('andaman')
      );
    
    return (
        <div>
        <HelmetProvider>
         <Helmet>
            <title>Andaman Tour Packages - Explore Pristine Beaches & Scenic Islands</title>
            <meta
                name="description"
                content="Discover the beauty of Andaman with our exclusive tour packages. From crystal-clear beaches to exotic marine life and thrilling water activities, explore all that Andaman has to offer. Plan your perfect island getaway now!"
            />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <Callback contactNumber="+91 9141001434"/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
                        <Fade>
                        <h1 className='home-head-andaman mt-5'>Andaman Bliss</h1>
                        <p className='destination-description'>The Andaman and Nicobar Islands, located in the Bay of Bengal, are an idyllic destination known for their pristine beaches, coral reefs, tropical forests, and rich marine life. These islands offer a perfect escape for beach lovers and adventure enthusiasts.</p>
                        <p className='destination-sub-head mt-5'>Geography</p>
                        <p className='destination-description'>The Andaman and Nicobar Islands are an archipelago of around 572 islands, of which only about 37 are inhabited. The islands are covered with dense tropical rainforests and surrounded by turquoise blue waters, making them a haven for eco-tourism.</p>
                        <p className='destination-sub-head mt-5'>International Airports in Kerala</p>
                        <p className='destination-description'><b>Veer Savarkar International Airport, Port Blair:</b> The primary gateway to the Andaman Islands.</p>
                        <p className='destination-description'>Flights from major cities like Delhi, Mumbai, and Kolkata connect to Port Blair.</p>
                        <p className='destination-sub-head mt-5'>Climate</p>
                        <p className='destination-description'><b>Summer (March to May):</b> Pleasant with clear skies, ideal for beach activities.</p>
                        <p className='destination-description'><b>Monsoon (June to September):</b> Heavy rainfall with high humidity, not recommended for water activities.</p>
                        <p className='destination-description'><b>Winter (November to February):</b> The best time to visit with cool, dry weather.</p>
                        <p className='destination-sub-head mt-5'>Culture</p>
                        <p className='destination-description'><b>Languages:</b> Hindi, Bengali, Tamil, and Telugu are spoken widely. English is also understood.</p>
                        <p className='destination-description'><b>Festivals:</b> Islanders celebrate Durga Puja, Diwali, and Christmas, along with local festivals like Island Tourism Festival.</p>
                        <p className='destination-description'><b>Crafts:</b> Known for wood carving, shell craft, and woven textiles.</p>
                        <p className='destination-sub-head mt-5'>VISA formalities for Foreign Nationals</p>
                        <p className='destination-description'>Foreign nationals need to obtain a Restricted Area Permit (RAP) to visit the Andaman Islands. This can be acquired at the airport on arrival or at Indian embassies abroad. Some islands may require additional permits for access.</p>
                        <p className='destination-sub-head mt-5'>Tourism</p>
                        <p className='destination-description'>Here are some key tourist locations and attractions in the Andaman Islands:</p>
                        <p className='destination-sub-head'>1. Port Blair</p>
                        <p className='destination-description'><b>Cellular Jail:</b> A historic colonial prison turned museum, highlighting India’s struggle for independence.</p>
                        <p className='destination-description'><b>Ross Island:</b> Once the administrative headquarters of the British, now a beautiful, deserted island with colonial ruins.</p>
                        <p className='destination-description'><b>Corbyn’s Cove:</b> A popular beach known for its scenic beauty and water sports.
                        </p>
                        <p className='destination-sub-head'>2. Havelock Island (Swaraj Dweep)</p>
                        <p className='destination-description'><b>Radhanagar Beach:</b> Frequently listed among the best beaches in Asia, known for its crystal-clear waters and white sand.</p>
                        <p className='destination-description'><b>Elephant Beach:</b> A great spot for snorkeling and other water activities.</p>
                        <p className='destination-description'><b>Kalapathar Beach:</b> A serene beach with black rocks and golden sand.</p>
                        <p className='destination-sub-head'>3. Neil Island (Shaheed Dweep)</p>
                        <p className='destination-description'><b>Bharatpur Beach:</b> Ideal for swimming and coral viewing.</p>
                        <p className='destination-description'><b>Laxmanpur Beach:</b> Known for its stunning sunset views.</p>
                        <p className='destination-description'><b>Natural Bridge:</b> A unique rock formation resembling a bridge.</p>
                        <p className='destination-sub-head'>4. Baratang Island</p>
                        <p className='destination-description'><b>Limestone Caves:</b> A unique experience, these caves are formed by the mineral deposits in the region.</p>
                        <p className='destination-description'><b>Mud Volcanoes:</b> A rare natural occurrence, with mud oozing from beneath the surface.</p>
                        <p className='destination-sub-head'>5. North Andaman</p>
                        <p className='destination-description'><b>Diglipur:</b> Known for its green landscapes and eco-friendly tourism.</p>
                        <p className='destination-description'><b>Saddle Peak:</b> The highest point in the Andaman Islands, offering great trekking opportunities.</p>
                        <p className='destination-sub-head'>6. Adventure Activities:</p>
                        <p className='destination-description'><b>Scuba Diving:</b> Explore the rich coral reefs around Havelock Island and Neil Island.</p>
                        <p className='destination-description'><b>Snorkeling:</b> Popular in Elephant Beach and North Bay.</p>
                        <p className='destination-description'><b>Trekking: </b> Trek to Saddle Peak or through the tropical forests.</p>
                        <p className='destination-description'><b>Sea Walking:</b> Experience walking on the seabed at North Bay or Havelock.</p>
                        <h2 className='home-head-andaman mt-3'>Frequently Asked Questions(FAQ's)</h2>
                        <Faq faqData={faqData}/>
                        </Fade>
                        </div>
                    </div>
                </div>
                <p className='destination-package-head mb-3 mt-5'>View Our Andaman Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {keralaPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
        </HelmetProvider>
        </div>
    );
}

export default DestinationAndaman;
