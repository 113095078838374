import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa6";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < lastScrollY && currentScrollY > 0) {
       
        setIsVisible(true);
      } else {
       
        setIsVisible(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {isVisible && (
        <div
          onClick={scrollToTop}
          style={{
            position: "fixed",
            top: "83%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
            zIndex: "998",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "8px 16px",
            background: "black",
            color:'#fff',
            border: "1px solid #ccc",
            borderRadius: "24px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <FaArrowUp size={20}/>
          <span style={{ fontSize: "14px", fontWeight: "bold", fontFamily:'Roboto' }}>Back to Top</span>
        </div>
      )}
    </>
  );
};

export default ScrollToTopButton;
