import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import DummyForm from '../DummyForm';
import './index.css';

const OfferAndPackages = () => {
  const [isButtonLeft, setIsButtonLeft] = useState(true);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsButtonLeft((prev) => !prev);
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      const db = firebase.firestore();
      try {
        const snapshot = await db.collection("PackageTemplate").get();
        const packageList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPackages(packageList);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, []);

  const keralaPackages = packages.filter(pkg => pkg.destinationName === 'bali');
  
  return (
    <div className="container mt-5">
      {isFormOpen && (
        <div className="">
          <div className="enquire-form-container">
            <DummyForm onClose={closeForm}/> 
          </div>
        </div>
      )}
      <div className="row justify-content-between align-items-center">

        <div className="col-12 col-md-3 d-flex justify-content-center align-items-center mb-3">
          <div className={`offer-button ${isButtonLeft ? "blinking" : ""}`} onClick={openForm} style={{width:'200px', height:'200px'}}>
            <span className="stars">★</span>
            <span className="offer-text">Get 10% OFF<br />
              <span style={{ fontStyle: 'italic' }}>Click Here</span>
            </span>
            <span className="stars">★</span>
          </div>
        </div>

        <div className="col-12 col-md-6 d-flex justify-content-center">
          <div className="destinations-container mb-5">
            <div className="best-package-list d-flex flex-wrap justify-content-center">
              {keralaPackages.map((pkg) => (
                <div key={pkg.id} className="package-list-card mb-3">
                  <img
                    src={pkg.bannerImageUrl}
                    alt={pkg.destinationName}
                    className="list-card-img mb-3"
                  />
                  <div>
                    <p className="title-list mb-3">{pkg.destinationHead}</p>
                    <p className="title-dates-list bold mb-3">{pkg.noOfDays}</p>
                    <p className='mt-3 package-template-price'>
                      <span style={{ paddingLeft: '10px' }}>
                        <b><span className="blinking-price">${pkg.newCost}</span></b>
                        <span className="linethrough-price"> ${pkg.originalPrice}</span>
                        <span style={{ marginLeft: '5px' }}>PP</span>
                      </span>
                    </p>
                    <div className="btn-container-list-card">
                      <Link to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`} className="blogs-link">
                        <button className="view-button-list-card">
                          View Itinerary
                        </button>
                      </Link>
                      <Link to="/form" className="blogs-link">
                        <button className="view-button-list-card-book">
                          Book Now
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-12 col-md-3 d-flex justify-content-center align-items-center mb-3">
          <div className={`offer-button ${!isButtonLeft ? "blinking" : ""}`} onClick={openForm} style={{width:'200px', height:'200px'}}>
            <span className="stars">★</span>
            <span className="offer-text">Limited Time Offer<br />
              <span style={{ fontStyle: 'italic' }}>Click Here</span>
            </span>
            <span className="stars">★</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferAndPackages;
