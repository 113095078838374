import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {Fade} from 'react-awesome-reveal';
import Callback from '../Callback';
import './index.css';
import Faq from '../Faq';

const faqData = [
    {
      question: "1.What is the best time to visit the Netherlands?",
      answer: "The best time to visit is spring (March to May) for tulip season and summer (June to August) for warm weather and festivals."
    },
    {
      question: "2.Do I need a visa to visit the Netherlands?",
      answer: "It depends on your nationality. EU citizens do not need a visa, while others may require a Schengen visa."
    },
    {
      question: "3.What are the must-see places in Amsterdam?",
      answer: "The Anne Frank House, Rijksmuseum, Van Gogh Museum, and a canal cruise are unmissable highlights."
    },
    {
      question: "4.How can I travel around the Netherlands?",
      answer: "The country has an excellent public transport system, including trains, buses, and ferries. Renting a bike is also a great option."
    },
    {
      question: "5.Are there family-friendly activities in the Netherlands?",
      answer: "Yes! The Efteling theme park, Madurodam miniature city, and the NEMO Science Museum are perfect for families."
    }
  ];


const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/netherland%2Fneth-1.webp?alt=media&token=ebe7984b-4b3e-434b-84f4-790033f4432a",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/netherland%2Fneth-3.webp?alt=media&token=1be6d830-ce5b-4bfd-9025-8464d757ecc0",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/netherland%2Fneth-2.webp?alt=media&token=7da2445e-d559-4f82-a0d3-6ec3f96a13e8",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/netherland%2Fneth-4.webp?alt=media&token=e93fac31-a54b-46de-9069-a3d249051737",
]

const DestinationNetherland = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const himachalPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('netherland')
      );

    return (
        <div>
        <HelmetProvider>
        <Helmet>
            <title>Explore the Netherlands - Tulips, Canals, and Iconic Landmarks</title>
            <meta
                name="description"
                content="Plan your trip to the Netherlands and discover tulip fields, historic canals, vibrant cities like Amsterdam, and family-friendly attractions. Travel tips, visa info, and must-visit places included."
            />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <Callback contactNumber="+91 9845090014"/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 neth-container mt-5'>
                     <Fade>  
                        <h1>Explore the Netherlands: A Journey Through Tulips, Canals, and Culture</h1>
                        <p>Welcome to the Netherlands, a country of captivating landscapes, rich history, and vibrant culture. From the iconic windmills to the stunning tulip fields, every corner of the Netherlands offers a unique experience. Whether you're visiting Amsterdam’s canals or exploring quaint villages, there’s something here for everyone.</p>
                        <h2>Visa Formalities</h2>
                        <p>To visit the Netherlands, check if you need a Schengen Visa. EU citizens and nationals of certain countries can enter visa-free for up to 90 days. Others must apply for a visa before travel.</p>
                        <p>Apply for your Schengen Visa via the official Netherlands visa application website:
                        <a href='https://www.netherlandsworldwide.nl/visa-the-netherlands/schengen-visa/apply-india' target='__blank'>Netherlands Visa Application Official Website</a></p>
                        <h2>Top Destinations in the Netherlands</h2>
                        <h3>1. Amsterdam</h3>
                        <ul>
                          <li><b>Must-See Attractions:</b> Anne Frank House, Rijksmuseum, Van Gogh Museum, and the famous canals.</li>
                          <li><b>Activities:</b> Take a canal cruise, visit local markets, and explore the trendy Jordaan district.</li>
                        </ul>
                        <h3>2. Keukenhof Gardens</h3>
                        <ul>
                          <li>Known as the "Garden of Europe," it features millions of tulips and is best visited in spring.</li>
                        </ul>
                        <h3>3. Zaanse Schans</h3>
                        <ul>
                        <li>Explore a traditional Dutch village with windmills, cheese factories, and wooden shoe workshops.</li>
                        </ul>
                        <h3>4. Rotterdam</h3>
                        <ul>
                        <li>Famous for modern architecture, vibrant nightlife, and cultural landmarks like the Cube Houses and Markthal.</li>
                        </ul>
                        <h3>5. The Hague</h3>
                        <ul>
                        <li>Visit the Peace Palace, explore Scheveningen Beach, and admire world-class art at Mauritshuis.</li>
                        </ul>
                        <h2>Unique Experiences in the Netherlands</h2>
                        <ul>
                          <li><b>Cycling Adventures:</b> The Netherlands is bike-friendly, making it ideal for cycling through tulip fields and countryside.</li>
                          <li><b>Food and Drink:</b> Try traditional Dutch treats like stroopwafels, bitterballen, and Dutch pancakes. Pair them with local beers or jenever.</li>
                          <li><b>Seasonal Highlights:</b> Tulip festivals in spring, open-air markets in summer, and ice skating on frozen canals in winter.</li>
                        </ul>
                        <h2>Travel Tips for the Netherlands</h2>
                        <ul>
                          <li><b>Transportation:</b> Efficient public transit, including trains, buses, and ferries, plus widespread bike rentals.</li>
                          <li><b>Best Time to Visit:</b> Spring (March-May) for tulip fields and summer (June-August) for outdoor activities.</li>
                          <li><b>Language:</b> Dutch is the official language, but English is widely spoken.</li>
                        </ul>
                        <h2>Frequently Asked Questions(FAQs)</h2>
                        <Faq faqData={faqData}/>
                        </Fade>
                    </div>
                </div>
            </div>
                <p className='neth-destination-head mb-3 mt-5'>View Our Netherland Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {himachalPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                      ))}
                    </div>
                  </div>
              <Footer/>
            </HelmetProvider>
        </div>
    );
}

export default DestinationNetherland;
