import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import axios from 'axios';
import Callback from '../Callback';
import Faq from "../Faq";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {Fade} from 'react-awesome-reveal';
import './index.css';

const faqData = [
  {
    question: "1. What is the best time to visit Kenya?",
    answer: `The best time to visit Kenya is during the dry seasons, from January to March and June to October. These months are ideal for wildlife viewing, as animals gather around water sources, and the skies remain clear. July to October is also the perfect time to witness the Great Migration in the Maasai Mara.`
  },
  {
    question: "2. Do I need a visa to travel to Kenya?",
    answer: `Yes, most travelers require a visa to enter Kenya. You can apply for an eVisa online before your trip. Some nationalities may also obtain a visa on arrival at the airport. It’s best to check the Kenyan immigration website for the latest visa policies and requirements for your nationality.`
  },
  {
    question: "3. What vaccinations are required before visiting Kenya?",
    answer: `Travelers to Kenya are often advised to have the following vaccinations:<br>
             - <strong>Yellow fever</strong> (recommended and sometimes mandatory)<br>
             - <strong>Typhoid</strong><br>
             - <strong>Hepatitis A and B</strong><br>
             - <strong>Tetanus and diphtheria</strong><br>
             - <strong>Rabies</strong>, depending on your activities<br>
             Consult a healthcare provider for tailored advice based on your health history and travel itinerary.`
  },
  {
    question: "4. Is Kenya safe for tourists?",
    answer: `Kenya is generally safe for tourists, especially in popular tourist areas like Nairobi, Maasai Mara, Amboseli, and the Kenyan coast. However, visitors should stay alert, avoid isolated areas after dark, and follow local safety guidelines. It’s always recommended to use reputable tour guides and operators.`
  },
  {
    question: "5. What currency is used in Kenya, and can I use credit cards?",
    answer: `The official currency of Kenya is the Kenyan Shilling (KES). While cash is widely used, credit cards (Visa and Mastercard) are accepted at most hotels, restaurants, and major establishments in urban centers. ATMs are available in cities and tourist areas for withdrawing local currency.`
  },
  {
    question: "6. What are the must-visit destinations in Kenya?",
    answer: `Kenya offers a diverse range of attractions, including:<br>
             - <strong>Maasai Mara National Reserve</strong>: Famous for the Great Migration.<br>
             - <strong>Amboseli National Park</strong>: Renowned for views of Mount Kilimanjaro.<br>
             - <strong>Lake Nakuru</strong>: Known for flamingos and rhinos.<br>
             - <strong>Diani Beach</strong>: A stunning coastal retreat.<br>
             - <strong>Nairobi</strong>: Vibrant capital city with attractions like the Giraffe Centre and Nairobi National Park.`
  },
  {
    question: "7. What kind of wildlife can I expect to see in Kenya?",
    answer: `Kenya is home to incredible wildlife, including the Big Five (lion, leopard, elephant, buffalo, and rhinoceros). You can also spot giraffes, zebras, wildebeests, cheetahs, flamingos, and countless bird species. Kenya’s diverse ecosystems provide ample opportunities for spectacular wildlife viewing.`
  },
  {
    question: "8. What is a safari in Kenya like?",
    answer: `A Kenyan safari offers a chance to explore breathtaking landscapes and observe wildlife in their natural habitat. Safaris can range from luxury lodges to more budget-friendly camping experiences. Game drives typically occur during early morning and late afternoon when animals are most active.`
  },
  {
    question: "9. How can I get around Kenya?",
    answer: `Transportation options in Kenya include:<br>
             - Domestic flights: Quick and convenient for longer distances.<br>
             - Private safari vehicles and drivers: Ideal for flexibility and comfort.<br>
             - Matatus (minibuses) and buses: Common local transport, but less reliable for tourists.<br>
             - Car rentals: Recommended only if you are familiar with driving conditions.`
  },
  {
    question: "10. What should I pack for a Kenyan safari?",
    answer: `Essentials for a Kenyan safari include:<br>
             - Light, breathable clothing in neutral colors.<br>
             - Comfortable walking shoes and sandals.<br>
             - Sunscreen, hat, and sunglasses to protect against the sun.<br>
             - Binoculars for wildlife viewing.<br>
             - Insect repellent and any necessary medications.<br>
             - A good camera for capturing memorable moments.`
  },
  {
    question: "11. Are there cultural customs I should be aware of in Kenya?",
    answer: `Kenya has a rich cultural heritage, so respecting local customs is important. Dress modestly in rural areas, always ask for permission before taking photos of people, and greet locals with a handshake. Showing respect and curiosity toward Kenya’s diverse traditions and languages will enhance your experience.`
  },
  {
    question: "12. What traditional foods should I try in Kenya?",
    answer: `Some popular dishes to try in Kenya include:<br>
             - <strong>Nyama Choma</strong> (grilled meat)<br>
             - <strong>Ugali</strong> (maize flour dish)<br>
             - <strong>Sukuma Wiki</strong> (collard greens)<br>
             - <strong>Chapati</strong> (flatbread)<br>
             - <strong>Samosas and Mandazi</strong> (snacks)`
  },
  {
    question: "13. Can I drink tap water in Kenya?",
    answer: `It is not recommended to drink tap water in Kenya. Stick to bottled, filtered, or boiled water to avoid potential health issues. Ice in drinks may also be made with tap water, so ask when in doubt.`
  },
  {
    question: "14. What languages are spoken in Kenya?",
    answer: `Kenya’s official languages are English and Swahili. While English is widely spoken, learning some basic Swahili phrases can enhance your interactions with locals. A simple greeting like "Jambo" (Hello) is always appreciated.`
  },
  {
    question: "15. Are there any fees or permits required for national parks?",
    answer: `Yes, visitors to Kenyan national parks and reserves must pay an entrance fee. Fees vary depending on the park, age, and whether you are a resident or non-resident. Make sure to verify current fees on the Kenya Wildlife Service (KWS) website or with your travel provider.`
  }
];

const images = [
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenya%2Fkenya-1.webp?alt=media&token=47d1c8db-d8be-4648-9241-49778f159e09",
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenya%2Fkenya-3.webp?alt=media&token=9121223e-75a1-4773-8764-6bd1b879dc43",
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenya%2Fk-2.webp?alt=media&token=0c045198-92f2-4de8-a225-b698e4cb7adb",
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/greatmigration%2Fgm-1.webp?alt=media&token=a4673b41-99cd-4b4a-9179-0ac669ed1f50",
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenya%2Fk-4.webp?alt=media&token=cb704b83-d722-4305-b86a-4661adfd4785",
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/greatmigration%2Fgm-3.webp?alt=media&token=8eccc26f-cb99-4f8a-8028-4f171cf3ca9b"
]

const DestinationKenya = () => {
    const [packages, setPackages] = useState([]);
    const [cards, setCards] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("WildlifePackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };

        const fetchCards = async () => {
          try {
            const response = await axios.get('https://formbackend.netlify.app/api/cards');
            setCards(response.data);
          } catch (error) {
            console.error('Error fetching card items:', error);
          }
        };

        const fetchData = async () => {
          await fetchPackages();
          await fetchCards();
        };
    
        fetchData();
      }, []);

      const kenyaPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('kenya')
      );

      const combinedItems = [ ...cards, ...kenyaPackages];
    
    return (
        <>
         <HelmetProvider>
         <Helmet>
            <title>Best Kenya Safari Tour Operators in Bangalore | Travel Unbounded</title>
            <meta
                name="description"
                content="Explore top wildlife safaris with the best Kenya tour operators in Bangalore. Book unforgettable experiences with Travel Unbounded."
                />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <Callback contactNumber="+91 9845090014" />
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 kenya-container mt-5'>
                      <Fade>
                      <h1>Kenya: The Ultimate Wildlife Safari Destination</h1>
                      <p>Kenya is celebrated as one of Africa's premier wildlife safari destinations, drawing countless travelers from around the globe. With its stunning national parks, Kenya is renowned for its incredible variety of wildlife and is home to the famous "Big 5."</p>
                      <h2>Explore Kenya's National Parks and Wildlife</h2>
                      <h3>1. Maasai Mara National Park</h3>
                      <h3>Overview</h3>
                      <p>Maasai Mara is the crown jewel of Kenya's wildlife safari experience. This iconic park features vast savannahs adorned with acacia trees and is the epicenter of The Great Migration from mid-July to early October. During this time, millions of wildebeests, zebras, and antelopes cross the Mara River in search of greener pastures.</p>
                      <h3>Highlights:</h3>
                      <ul>
                        <li>Witness the Big 5 and the Small 5 in their natural habitat.</li>
                        <li>Best visited from June to October; April should be avoided due to slushy conditions.</li>
                        <li>An unforgettable wildlife safari adventure awaits nature lovers and enthusiasts.</li>
                      </ul>
                      <h3>2. Amboseli National Park</h3>
                      <h3>Overview:</h3>
                      <p>Situated at the foot of the majestic Mount Kilimanjaro, Amboseli is famous for its striking volcanic landscapes and is home to some of the largest elephants, known as tuskers.</p>
                      <h3>Highlights</h3>
                      <ul>
                        <li>Experience the awe of seeing elephants with tusks that touch the ground.</li>
                        <li>Flamingos flock to the park’s salt lakes, creating stunning pink landscapes.</li>
                        <li>Amboseli offers a remarkable setting for wildlife photography and safari experiences.</li>
                      </ul>
                      <h3>3. Samburu National Reserve</h3>
                      <h3>Overview:</h3>
                      <p>Samburu is one of Kenya's hidden gems, rich in wildlife and located alongside the Ewaso Nyiro River. This reserve is essential for a complete wildlife safari experience in Kenya.</p>
                      <h3>Highlights</h3>
                      <ul>
                        <li>Spot rare northern species such as Grevy's zebra and reticulated giraffe.</li>
                        <li>The reserve is famous for its packs of painted wild dogs, known for their intelligence in hunting.</li>
                        <li>A must-visit for those seeking unique wildlife encounters off the beaten path.</li>
                      </ul>
                      <h3>4. Lake Nakuru National Park</h3>
                      <h3>Overview:</h3>
                      <p>Initially recognized for its birdlife, Lake Nakuru was designated a National Park in 1968 and is now known for hosting a large population of white rhinos.</p>
                      <h3>Highlights</h3>
                      <ul>
                        <li>The lake covers a significant portion of the park, attracting thousands of flamingos, making it a birdwatcher's paradise.</li>
                        <li>Explore diverse ecosystems teeming with wildlife, perfect for photography and safari tours.</li>
                        <li>Enjoy breathtaking views and an array of animal species.</li>
                      </ul>
                      <h3>5. Nairobi National Park</h3>
                      <h3>Overview:</h3>
                      <p>Unique for its proximity to Nairobi, this park provides a rare opportunity to see wildlife in a natural setting close to the city.</p>
                      <h3>Highlights</h3>
                      <ul>
                        <li>Spot black rhinos, especially after their successful reintroduction to the park.</li>
                        <li>A must-visit for every traveler to Nairobi, offering an extraordinary blend of urban and natural experiences.</li>
                      </ul>
                      <h2>Must-See Attractions in Nairobi</h2>
                      <h3>Giraffe Centre</h3>
                      <p>Located in Langata, just outside Nairobi, the Giraffe Centre is dedicated to breeding the endangered Rothschild giraffe. Visitors can learn about these majestic creatures and even feed them with care.</p>
                      <h3>David Sheldrik Elephant Orphanage</h3>
                      <p>Founded by Daphne Sheldrik, this orphanage is dedicated to rescuing elephant and rhino calves. The daily bathing and feeding sessions are heartwarming highlights for animal lovers and safari enthusiasts alike.</p>
                      <h2>Visa Requirements</h2>
                      <ul>
                        <li>E-Visa: Foreign nationals traveling to Kenya can apply for an E-Visa. Check the eligibility list for exemptions.</li>
                        <li>Application: <a href='http://evisa.go.ke/eligibility.html' target='__blanck'>Apply for an E-Visa</a></li>
                        <li>Visa-Free Entry: Starting January 2024, Indian nationals can visit Kenya without a visa.</li>
                      </ul>
                      <h3>Climate in Kenya</h3>
                      <p>Kenya boasts a diverse climate, generally characterized by warm and pleasant weather throughout the year. The dry seasons, which vary by region, are the best times for wildlife safaris and outdoor adventures.</p>
                      <h2>Frequently Asked Questions(FAQ's)</h2>
                      <Faq faqData={faqData}/>
                      </Fade>
                    </div>
                </div>
            </div>
                <p className='kenya-destination-head mb-3 mt-5'>View Our Kenya Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {combinedItems.map((item) => (
                    <div key={item.id} className="package-list-card mb-3">
                    <div className="image-container">
                      <img
                        src={item.bannerImageUrl || item.image}
                        alt={item.destinationName || item.name}
                        className="list-card-img mb-3"
                      />
                      {item.isSold && (
                        <span className="sold-out-tag">Sold Out</span>
                      )}
                    </div>
                    <div>
                      <p className="title-list mb-3">{item.destinationHead || item.name}</p>
                      <p className="title-dates-list bold mb-3">{item.noOfDays || item.days}</p>
                      {item.corbett ? (
                        <p className='mt-3 package-template-price title-rate-list'>
                          <span>
                            <b><span className="blinking-price">{item.newCost}</span></b>
                            <span className="linethrough-price"> {item.originalPrice}</span>
                            <span style={{ marginLeft: '5px' }}>PP</span>
                          </span>
                        </p>
                      ) : (
                        <p className="title-rate-list bold mb-3">{item.cost || item.price}</p>
                      )}
                      <div className="btn-container-list-card">
                        <Link
                          to={`/package/wildlife/${(item.destinationName || item.link).replace(/\s+/g, '-')}`}
                          className="blogs-link"
                        >
                          <button className="view-button-list-card" disabled={item.isSold}>View Itinerary</button>
                        </Link>
                        <Link to="/form" className="blogs-link">
                          <button className="view-button-list-card-book">Book Now</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
                </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </>
    );
}

export default DestinationKenya;
