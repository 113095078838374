import React, { useState, useEffect, useRef } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Link } from 'react-router-dom';
import './index.css';

const destinations = [
  { name: 'All', image: '/infy-logo.jpg' },
  { name: 'Kerala', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kerala%2Frice-field-8295148_1920.webp?alt=media&token=c5988df0-1d55-4cff-a1d0-094d5e16396d' },
  { name: 'Bali', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bali%2Fkelingking-beach-sunset-nusa-penida-island-bali-indonesia.webp?alt=media&token=68f3b9ad-7720-4302-96d4-243c690449ac' },
  { name: 'Himachal', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/himachal%2Fscenic-3598991_1920.webp?alt=media&token=cbaafe87-4f71-4e96-80cc-b4f71a125902' },
  { name: 'Vietnam', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/vietnam%2Fv-1.webp?alt=media&token=91070055-73d5-4888-94b1-f96146c17d0a' },
  { name: 'Singapore', image:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/singapore%2Fsin-1.webp?alt=media&token=ae3f83f0-48c5-44c2-b24e-02f813a5112d'},
  { name: 'Greece', image:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/greece%2Fgreece-2.webp?alt=media&token=d021b05e-c7b1-4b12-848a-aca3998cb102'},
  { name: 'Turkey', image:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/turkey%2Fturkey-1.webp?alt=media&token=ec6c874b-7468-412e-9a8c-d6cf0bb5d522'},
  { name: 'Dubai', image:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/dubai%2Fdubai-1.webp?alt=media&token=0e2454d4-9391-4c24-8f6f-7377a1c78bcc'},
  { name: 'Switzerland', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/switz%2Fswitz-7.webp?alt=media&token=9b9db35f-4362-4d2f-b950-204b03d77b04'},
  { name: 'Netherland', image:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/netherland%2Fneth-1.webp?alt=media&token=ebe7984b-4b3e-434b-84f4-790033f4432a'},
  { name: 'Malaysia', image:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/malaysia%2Fmal-1.webp?alt=media&token=a01fe861-1c26-4614-9f0a-404976906142'},
  { name: 'Andaman', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/andaman%2Fwaranont-joe-9STjhlddEdI-unsplash.jpg?alt=media&token=a8aeed69-f232-453f-9017-46f943a3ef36' },
  { name: 'Nepal', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/nepal%2FSHN549.jpg?alt=media&token=d4a0dbc2-6bc7-4e9b-b387-cdadddc3cd0d' },
  { name: 'Bhutan', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bhutan%2Fb-10.webp?alt=media&token=1e1264ce-32bd-4326-877b-ac8e408f8604' },
  { name: 'Iceland', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Iceland%2Fpexels-simonmigaj-1009136-2.webp?alt=media&token=4ac3c764-da3f-4f74-998f-8c9009bd691a' },
];

const destinationKeywords = {
  Himachal: ['himachal', 'spiti', 'lahaul', 'shimla'],
  Kerala: ['kerala'],
  Bali: ['bali'],
  Andaman: ['andaman'],
  Nepal: ['nepal'],
  Bhutan: ['bhutan'],
  Vietnam: ['vietnam'],
  Singapore: ['singapore'],
  Greece: ['greece', 'greek'],
  Iceland: ['iceland'],
  Turkey: ['turkey'],
  Dubai: ['dubai'],
  Switzerland: ['switzerland'],
  Malaysia: ['malaysia'],
  Netherland: ['netherland']
};

const Packages = () => {
  const [selectedDestination, setSelectedDestination] = useState('All');
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const fetchPackages = async () => {
      setIsLoading(true);
      const db = firebase.firestore();
      try {
        const snapshot = await db.collection("PackageTemplate").get();
        const packageList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPackages(packageList);
      } catch (error) {
        console.error("Error fetching packages:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPackages();
  }, []);

  const filteredPackages = selectedDestination === 'All'
    ? packages
    : packages.filter(pkg => {
        const keywords = destinationKeywords[selectedDestination] || [selectedDestination];
        return keywords.some(keyword =>
          pkg.destinationName.toLowerCase().includes(keyword.toLowerCase())
        );
      });
      
  const sortedPackages = filteredPackages.sort((a, b) => a.id - b.id);

  const handleDestinationClick = (destinationName) => {
    setSelectedDestination(destinationName);
  };

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -200 : 200,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="packages-container-search">
      <div className="scrollable-buttons-container">
        <button
          className="scroll-arrow left-arrow"
          onClick={() => handleScroll('left')}
        >
          &#8249;
        </button>
        <div className="buttons-container-search" ref={scrollContainerRef}>
          {destinations.map((destination) => (
            <button
              key={destination.name}
              className={`destination-btn-search ${selectedDestination === destination.name ? 'active' : ''}`}
              onClick={() => handleDestinationClick(destination.name)}
            >
              <div className="destination-image-search" style={{ backgroundImage: `url(${destination.image})` }}></div>
              <span>{destination.name}</span>
            </button>
          ))}
        </div>
        <button
          className="scroll-arrow right-arrow"
          onClick={() => handleScroll('right')}
        >
          &#8250;
        </button>
      </div>

      <div className="destinations-container">
        {isLoading ? (
          <div className='loading-container'>
            <p className="loading"></p>
          </div>
        ) : sortedPackages.length > 0 ? (
          <div className="best-package-list">
            {sortedPackages.map((pkg) => (
              <div key={pkg.id} className="package-list-card mb-3">
                <div className="image-container">
                  <img
                    src={pkg.bannerImageUrl}
                    alt={pkg.destinationName}
                    className="list-card-img mb-3"
                  />
                  {pkg.recommend && (
                    <span className="recommend-tag">{pkg.tag}</span>
                  )}
                </div>
                <div>
                  <p className="title-list mb-3">{pkg.destinationHead}</p>
                  <p className="title-dates-list bold mb-3">{pkg.noOfDays}</p>
                  {pkg.isOffer ? (
                    <p className='mt-3 package-template-price title-rate-list'>
                      <span>
                        <b><span className="blinking-price">{pkg.newCost}</span></b>
                        <span className="linethrough-price"> {pkg.originalPrice}</span>
                        <span style={{ marginLeft: '5px' }}>PP</span>
                      </span>
                    </p>
                  ) : (
                    <p className="title-rate-list bold mb-3">{pkg.cost}</p>
                  )}
                  <div className="btn-container-list-card">
                    <Link
                      to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                      className="blogs-link"
                    >
                      <button className="view-button-list-card">
                        View Itinerary
                      </button>
                    </Link>
                    <Link to="/form" className="blogs-link">
                      <button className="view-button-list-card-book">
                        Book Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='search-container-notfound'>
            <p className="no-results-search">No packages found for the selected destination.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Packages;
