import { Link } from "react-router-dom";
import "./index.css";
import { HelmetProvider, Helmet } from 'react-helmet-async';

const NotFound = () => {
  const onClickBackToHome = () => {
    window.history.back("/");
  };

  return (
    <>
    <HelmetProvider>
    <Helmet>
    </Helmet>
    <div className="not-found-container">
      <h1 className="input-text mb-3 text-center">404 <br/>Page Not Found</h1>
      <p
        className="
      input-text mb-3"
      >
        we are sorry, the page you requested could not be found, please go back
        to the homepage
      </p>
      <Link to="/">
        <button
          type="button"
          className="btn btn-primary"
          onClick={onClickBackToHome}
        >
          Go Back to Home
        </button>
      </Link>
    </div>
    </HelmetProvider>
    </>
  );
};

export default NotFound;
