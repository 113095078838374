import React from "react";
import Slider from "react-slick";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import "./index.css";

const Gallery = () => {
  const sliderRef = React.useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Disable default arrows
  };

  const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-2.webp?alt=media&token=d8038cc3-1116-405b-885e-ecc026b4d34f",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-1.webp?alt=media&token=a0a8c5df-13eb-4232-900c-ccee2f0c86f8",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-3.webp?alt=media&token=b4b2e840-a9c7-4550-94e8-86b2b4ceba77",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-4.webp?alt=media&token=0fbe4255-967f-4550-9822-11743e05d05c",
  ];

  return (
    <div className="gallery-container-kenya">
      <div className="gallery-kenya">
        <Slider ref={sliderRef} {...settings}>
          {images.map((image, index) => (
            <div key={index} className="gallery-slide-kenya">
              <img src={image} alt={`Slide ${index + 1}`} className="gallery-image-kenya" />
            </div>
          ))}
        </Slider>
        <div
          className="arrow-home-kenya left-arrow-home-kenya"
          onClick={() => sliderRef.current.slickPrev()}
        >
          <BsArrowLeft size={20} />
        </div>
        <div
          className="arrow-home-kenya right-arrow-home-kenya"
          onClick={() => sliderRef.current.slickNext()}
        >
          <BsArrowRight size={20} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
