import React, { useState, useEffect } from 'react';
import { FiMessageCircle } from 'react-icons/fi';
import DummyForm from '../DummyForm'; 
import './index.css';

const EnquireButton = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formOpenedViaScroll, setFormOpenedViaScroll] = useState(false);

  const toggleForm = () => {
    setIsFormOpen((prev) => !prev);
  };

  const onClose = () => {
    setIsFormOpen(false)
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500 && !formOpenedViaScroll && !isFormOpen) {
        
        setFormOpenedViaScroll(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [formOpenedViaScroll, isFormOpen]);

  return (
    <div className="enquire-button-container-home">
      {!isFormOpen && (
        <button className="enquire-button" aria-label="Enquire Now" onClick={toggleForm}>
          <FiMessageCircle className="enquire-icon" size={24} />
        </button>
      )}
      {isFormOpen && (
        <div>
          <DummyForm onClose={onClose} />
        </div>
      )}
    </div>
  );
};

export default EnquireButton;
