import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Scroll from "./components/Scroll";
import Whatsapp from './components/Whatsapp';
import Home from "./components/Home";
import BlogList from "./components/BlogList";
import About from "./components/About";
import DestinationKenya from "./components/DestinationKenya";
import DestinationVietnam from "./components/DestinationVietnam";
import DestinationTanzania from "./components/DestinationTanzania";
import DestinationBandhavgarh from "./components/DestinationBandhavgarh";
import DestinationRanthambore from "./components/DestinationRanthambore";
import DestinationCorbett from "./components/DestinationCorbett";
import DestinationKabini from "./components/DestinationKabini";
import DestinationBandipur from "./components/DestinationBandipur";
import DestinationKerala from "./components/DestinationKerala";
import DestinationHimachal from "./components/DestinationHimachal";
import DestinationIceland from "./components/DestinationIceland";
import DestinationAndaman from "./components/DestinationAndaman";
import DestinationNepal from "./components/DestinationNepal";
import DestinationBali from "./components/DestinationBali";
import DestinationBhutan from "./components/DestinationBhutan";
import DestinationGreece from "./components/DestinationGreece";
import DestinationSingapore from "./components/DestinationSingapore";
import DestinationTurkey from "./components/DestinationTurkey";
import DestinationDubai from "./components/DestinationDubai";
import DestinationSwitzerland from "./components/DestinationSwitzerland";
import DestinationMalaysia from "./components/DestinationMalaysia";
import DestinationNetherland from "./components/DestinationNetherland";
import PayNow from "./components/PayNow";
//import Form from "./components/Form";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import NotFound from "./components/NotFound";
import SearchNotFound from "./components/SearchNotFound";
import Africa1 from './components/Africa-1';
import Africa2 from './components/Africa-2';
import Africa4 from './components/Africa-4';
import Africa5 from './components/Africa-5';
import SouthAfrica from './components/SouthAfrica';
import EnquireButton from "./components/EnquireButton";
import PackageTemplate from "./components/PackageTemplate";
import AddPackages from "./components/AddPackages";
import AddWildlifePackages from "./components/AddWildlifePackages";
import PackageList from "./components/PackageList";
import Contact from "./components/Contact";
import Cancellation from "./components/Cancellation";
import Quiz from "./components/Quiz";
import AddBlog from "./components/AddBlog";
import AddCustomerBlog from './components/CustomerBlogs';
import CustomerBlogDetails from './components/CustomerBlogDetail';
import QuizForm from './components/QuizForm';
import AdminDashboard from './components/DashboardLogin';
import WildlifePackageTemplate from './components/WildlifePackageTemplate';
import KenyaKids from "./components/KenyaKids";
import NewForm from "./components/NewForm";
//import Test from "./components/Test";
//import Kerala from './components/Kerala';
//import Filter from './components/FilterWildlife';
//import KidsCopy from './components/KidsCopy';
//import BookingForm from "./components/BookingForm";
import DestinationTemplate from './components/DestinationTemplate';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/form" element={<NewForm />} />
        <Route path="/add-leisure-package" element={<AddPackages />} />
        <Route path="/add-wildlife-package" element={<AddWildlifePackages />} />
        <Route path="/add-blog" element={<AddBlog />} />
        <Route path="/add-quiz" element={<QuizForm />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/destination-template" element={<DestinationTemplate />} /> 
        <Route path="/add-customer-blog" element={<AddCustomerBlog />} />
        <Route path="/cancellation-refund-policy" element={<Cancellation />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/package/:destinationName/pay-now" element={<PayNow />} />
        <Route path="/package/wildlife/south-africa-tour/1" element={<Africa1 />} />
        <Route path="/package/wildlife/south-africa-tour/2" element={<Africa2 />} />
        <Route path="/package/wildlife/south-africa-tour/3" element={<Africa4 />} />
        <Route path="/package/wildlife/south-africa-tour/4" element={<Africa5 />} />
        <Route
          path="*"
          element={
            <>
              <Whatsapp />
              <EnquireButton/>
              <Scroll />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/kenya" element={<DestinationKenya />} />
                {/*<Route path="/test" element={<Test />} /> */}
                <Route path="/tanzania" element={<DestinationTanzania />} />
                <Route path="/vietnam" element={<DestinationVietnam />} />
                <Route path="/bandhavgarh" element={<DestinationBandhavgarh />} />
                <Route path="/ranthambore" element={<DestinationRanthambore />} />
                <Route path="/corbett" element={<DestinationCorbett />} />
                <Route path="/kabini" element={<DestinationKabini />} />
                <Route path="/bandipur" element={<DestinationBandipur />} />
                <Route path="/kerala" element={<DestinationKerala />} />
                <Route path="/himachal" element={<DestinationHimachal />} />
                <Route path="/iceland" element={<DestinationIceland />} />
                <Route path="/andaman" element={<DestinationAndaman />} />
                <Route path="/nepal" element={<DestinationNepal />} />
                <Route path="/bali" element={<DestinationBali />} />
                <Route path="/bhutan" element={<DestinationBhutan />} />
                <Route path="/greece" element={<DestinationGreece />} />
                <Route path="/singapore" element={<DestinationSingapore />} />
                <Route path="/turkey" element={<DestinationTurkey />} />
                <Route path="/dubai" element={<DestinationDubai />} />
                <Route path="/switzerland" element={<DestinationSwitzerland />} />
                <Route path="/malaysia" element={<DestinationMalaysia />} />
                <Route path="/netherland" element={<DestinationNetherland />} />
                <Route path="/about" element={<About />} />
                <Route path="/blogs" element={<BlogList />} />
                <Route path="/blog/:id" element={<CustomerBlogDetails />} />
                <Route path="/wildlife-packages" element={<SouthAfrica />} />
                <Route path="/leisure-packages" element={<PackageList />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/package/wildlife/magical-kenya" element={<KenyaKids />} />
                <Route path="/package/leisure/:destinationName" element={<PackageTemplate />} />
                <Route path="/package/wildlife/:destinationName" element={<WildlifePackageTemplate />} />
                <Route path="/search-not-found" element={<SearchNotFound />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
