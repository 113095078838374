import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {Fade} from 'react-awesome-reveal';
import Callback from '../Callback';
import './index.css';
import Faq from '../Faq';

const faqData = [
  {
    question: "1. What are the top tourist destinations in Malaysia?",
    answer: `Some of the must-visit destinations in Malaysia include:<br>
             - <strong>Kuala Lumpur</strong>: Petronas Twin Towers, Batu Caves.<br>
             - <strong>Penang</strong>: George Town, food street tours.<br>
             - <strong>Langkawi</strong>: Beaches, cable car rides.<br>
             - <strong>Sabah</strong>: Mount Kinabalu, Sipadan Island.<br>
             - <strong>Malacca</strong>: Historical sites, Jonker Street.`
  },
  {
    question: "2. What is the best time to visit Malaysia?",
    answer: "The best time to visit Malaysia is during the dry season, from March to October, when the weather is ideal for outdoor activities, beach trips, and exploring nature."
  },
  {
    question: "3. Do I need a visa to travel to Malaysia?",
    answer: "Visa requirements depend on your nationality. Citizens of many countries, including the U.S., U.K., Australia, and most European nations, can enter Malaysia visa-free for up to 90 days. Always check the latest visa requirements before traveling."
  },
  {
    question: "4. Is Malaysia a budget-friendly destination?",
    answer: "Yes, Malaysia caters to a wide range of budgets. Affordable accommodations, delicious street food, and reasonably priced transport make it a great option for budget travelers, while luxury resorts and fine dining cater to high-end travelers."
  },
  {
    question: "5. What are the best activities to do in Malaysia?",
    answer: `Top activities include:<br>
             - Exploring the vibrant city life of Kuala Lumpur.<br>
             - Diving in the crystal-clear waters of Sipadan Island.<br>
             - Visiting tea plantations in Cameron Highlands.<br>
             - Experiencing street food tours in Penang.<br>
             - Jungle trekking in Taman Negara.`
  },
  {
    question: "6. Is Malaysia safe for tourists?",
    answer: "Malaysia is considered a safe country for travelers. However, as with any destination, practice basic safety precautions like keeping your belongings secure and avoiding isolated areas at night."
  },
  {
    question: "7. What should I pack for a trip to Malaysia?",
    answer: `Pack the following for a comfortable trip:<br>
             - Lightweight, breathable clothing for the tropical climate.<br>
             - Comfortable shoes for walking and exploring.<br>
             - Sunblock, sunglasses, and a hat for outdoor activities.<br>
             - A raincoat or umbrella, especially during the rainy season.<br>
             - Travel adapters for Malaysia’s plug type (Type G).`
  },
  {
    question: "8. What is the local currency, and can I use credit cards?",
    answer: "The local currency is the Malaysian Ringgit (MYR). Credit cards are widely accepted in cities, hotels, and major establishments, but it’s advisable to carry some cash for small businesses and rural areas."
  },
  {
    question: "9. What languages are spoken in Malaysia?",
    answer: "The official language is Malay (Bahasa Malaysia), but English is widely spoken, especially in tourist areas, making it easy for travelers to communicate."
  },
  {
    question: "10. Are there any travel restrictions or COVID-19 guidelines?",
    answer: "As of now, most travel restrictions have been lifted, but it’s recommended to check the latest travel advisories, vaccination requirements, and health guidelines before planning your trip."
  },
  {
    question: "11. How do I get around in Malaysia?",
    answer: `There are various ways to get around:<br>
             - <strong>Public Transport</strong>: Well-connected network of buses, trains, and monorails.<br>
             - <strong>Taxis and Rideshares</strong>: Apps like Grab are popular and convenient.<br>
             - <strong>Car Rentals</strong>: Ideal for exploring less accessible areas.`
  },
  {
    question: "12. What are some famous dishes to try in Malaysia?",
    answer: `Don’t miss these culinary delights:<br>
             - <strong>Nasi Lemak</strong>: Coconut rice with accompaniments.<br>
             - <strong>Char Kway Teow</strong>: Stir-fried noodles.<br>
             - <strong>Laksa</strong>: Spicy noodle soup.<br>
             - <strong>Satay</strong>: Grilled meat skewers.<br>
             - <strong>Roti Canai</strong>: Flatbread with curry.`
  },
  {
    question: "13. Are there any cultural norms I should be aware of?",
    answer: `Yes, here are some cultural norms:<br>
             - Dress modestly when visiting religious sites.<br>
             - Remove your shoes before entering someone’s home or temples.<br>
             - Always ask permission before taking photos of locals.`
  },
  {
    question: "14. What makes Malaysia unique compared to other Southeast Asian countries?",
    answer: "Malaysia’s blend of diverse cultures, stunning natural beauty, and modern urban centers sets it apart. It offers everything from pristine beaches and rainforests to vibrant cities and rich heritage, making it a truly well-rounded destination."
  }
];


const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/malaysia%2Fmal-1.webp?alt=media&token=a01fe861-1c26-4614-9f0a-404976906142",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/malaysia%2Fmal-2.webp?alt=media&token=c2e27a7b-149a-453e-91e1-3c1fb61415ea",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/malaysia%2Fmal-3.webp?alt=media&token=364ad6ec-dd43-4b3e-a940-ba453e8bef53",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/malaysia%2Fmal-6.webp?alt=media&token=4d73664a-bc57-44ce-bdc4-9adf58d9b1af",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/malaysia%2Fmal-7.webp?alt=media&token=7982d45c-1f1f-403f-aca8-d76ba0427f36",
]

const DestinationMalaysia = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const himachalPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('malaysia')
      );

    return (
        <div>
        <HelmetProvider>
        <Helmet>
            <title>Explore Malaysia: A Blend of Modernity & Tradition | Travel Unbounded</title>
            <meta
                name="description"
                content="Discover Malaysia's stunning landscapes, vibrant culture, and iconic attractions. Plan your journey with insights on visa formalities, top destinations, and travel tips for an unforgettable experience."
            />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <Callback contactNumber="+91 9141001434"/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 mal-container mt-5'>
                     <Fade>    
                      <h1>Discover Malaysia: A Perfect Blend of Culture, Nature, and Adventure</h1>
                      <p>Malaysia is a land of mesmerizing contrasts, where modern skyscrapers meet lush rainforests, and bustling cities blend seamlessly with serene islands. Whether you’re seeking a cultural experience, a relaxing beach holiday, or an adrenaline-filled adventure, Malaysia has it all.</p>
                      <h2>Must-Visit Destinations in Malaysia</h2>
                      <ul>
                        <li><b>Kuala Lumpur:</b> Marvel at its skyline, shop till you drop at Bukit Bintang, and enjoy the lively food scene.</li>
                        <li><b>Penang:</b> Known as the food capital of Malaysia, Penang offers culinary delights and a cultural experience like no other.</li>
                        <li><b>Sabah and Sarawak:</b> Explore the wonders of Borneo, from Mount Kinabalu to the unique cultures of indigenous tribes.</li>
                        <li><b>Cameron Highlands:</b> Escape to the cool highlands and explore tea plantations, strawberry farms, and picturesque trails.</li>
                        <li><b>Malacca:</b> A UNESCO World Heritage Site, Malacca is rich in history, offering charming streets and centuries-old architecture.</li>
                      </ul>

                        <h2>Visa Formalities</h2> 
                        <p>Malaysia offers visa-free entry for citizens of many countries, allowing stays of up to 90 days for tourism and business purposes. Some nationalities may require a visa, which can be applied for online or at a Malaysian embassy/consulate.</p> 
                        <p>For eligible travelers, eVisa and eNTRI options are available for a simplified application process.Visit the official <a href='https://malaysiavisa.imi.gov.my/evisa/evisa.jsp' target='__blank'>Malaysian Immigration eVisa website</a> to check eligibility and apply online.</p>          
                        <h2>Top Reasons to Visit Malaysia</h2>
                        <ul>
                          <li><b>Diverse Cultures</b><br/>Malaysia is a melting pot of cultures, with Malay, Chinese, Indian, and indigenous influences shaping its vibrant identity. This diversity is reflected in its festivals, architecture, and especially its cuisine. Don’t miss the chance to indulge in traditional dishes like Nasi Lemak, Roti Canai, and Laksa.</li>
                          <li><b>Stunning Natural Beauty</b><br/>From pristine beaches to lush highlands, Malaysia offers a variety of breathtaking landscapes. Explore the iconic Langkawi Islands, trek through the ancient Taman Negara Rainforest, or relax on the white sands of Perhentian Islands.</li>
                          <li><b>Modern Urban Escapes</b><br/>Visit Kuala Lumpur, the capital city known for its iconic Petronas Twin Towers, vibrant street markets, and cutting-edge shopping malls. For history enthusiasts, George Town in Penang offers a glimpse into Malaysia’s colonial past with its heritage buildings and colorful street art.</li>
                          <li><b>Wildlife and Adventure</b><br/>Malaysia is home to a rich array of wildlife, including orangutans, proboscis monkeys, and tropical birds. Nature lovers can explore the Kinabalu National Park, a UNESCO World Heritage Site, or go diving in the crystal-clear waters of Sipadan Island, one of the world’s top diving destinations.</li>
                        </ul>
                        <h2>Travel Tips for Malaysia</h2>
                        <ul>
                          <li><b>Best Time to Visit:</b> Malaysia is a year-round destination, but the dry season (March to October) is ideal for outdoor activities.</li>
                          <li><b>Currency:</b> The Malaysian Ringgit (MYR) is the official currency.</li>
                          <li><b>Language:</b> While Malay is the official language, English is widely spoken in tourist areas.</li>
                          <li><b>Transportation:</b> Use the extensive public transport system or hire a car for a more flexible travel experience.</li>
                        </ul>
                        <h2>Why Book Your Malaysian Adventure with Us?</h2>
                        <p>At Travel Unbounded, we offer expertly curated travel packages to Malaysia that cater to all kinds of travelers. Whether you’re planning a solo trip, a family vacation, or a romantic getaway, our team ensures a hassle-free experience. From flight bookings to guided tours, we’ve got you covered!</p>
                        <h2>Frequently Asked Questions(FAQ's)</h2>
                        <Faq faqData={faqData}/>
                        </Fade>
                    </div>
                </div>
            </div>
                <p className='mal-destination-head mb-3 mt-5'>View Our Malaysia Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {himachalPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                      ))}
                    </div>
                  </div>
              <Footer/>
            </HelmetProvider>
        </div>
    );
}

export default DestinationMalaysia;
