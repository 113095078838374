import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {Fade} from 'react-awesome-reveal';
import Callback from '../Callback';
import './index.css';
import Faq from '../Faq';

const faqData = [
    {
      question: "1. What is the best time to visit Turkey?",
      answer: `The best times are spring (April to June) and autumn (September to November), offering pleasant weather and ideal conditions for sightseeing.`
    },
    {
      question: "2. What are the must-visit places in Turkey?",
      answer: `Top destinations include:<br>
               - <strong>Istanbul</strong>: Famous for its rich history and stunning architecture.<br>
               - <strong>Cappadocia</strong>: Known for its unique rock formations and hot air balloon rides.<br>
               - <strong>Antalya</strong>: A coastal city with beautiful beaches and resorts.<br>
               - <strong>Pamukkale</strong>: Renowned for its thermal pools and travertine terraces.<br>
               - <strong>Ephesus</strong>: An ancient city with remarkable ruins.`
    },
    {
      question: "3. Is Turkey safe for tourists?",
      answer: `Yes, Turkey is generally safe for travelers. However, always stay updated on local news and follow standard safety precautions.`
    },
    {
      question: "4. What should I pack for a trip to Turkey?",
      answer: `Pack comfortable clothing, a good pair of walking shoes, sunscreen, and a reusable water bottle. Seasonal packing will depend on your travel dates.`
    },
    {
      question: "5. How much does it cost to travel to Turkey?",
      answer: `Travel costs vary depending on your itinerary, but Turkey is known for offering luxurious experiences at affordable prices.`
    },
    {
      question: "6. Do I need a visa to visit Turkey?",
      answer: `Many nationalities can apply for an e-visa online. Check the official Turkish e-visa website for details.`
    },
    {
      question: "7. What are Turkey’s best culinary dishes?",
      answer: `Don’t miss trying:<br>
               - <strong>Kebabs</strong>: Grilled meat dishes.<br>
               - <strong>Turkish delights</strong>: Sweet, chewy confections.<br>
               - <strong>Baklava</strong>: A dessert made with layers of filo dough, nuts, and syrup.<br>
               - <strong>Manti</strong>: Turkish dumplings.<br>
               - <strong>Menemen</strong>: Turkish scrambled eggs with vegetables.`
    },
    {
      question: "8. Are there any unique cultural experiences in Turkey?",
      answer: `Yes, you can experience:<br>
               - Traditional <strong>Turkish tea culture</strong>.<br>
               - <strong>Belly dancing performances</strong>.<br>
               - Visiting local bazaars like the <strong>Grand Bazaar</strong> in Istanbul.`
    },
    {
      question: "9. Can I visit Turkey with my family?",
      answer: `Turkey is a family-friendly destination with activities and attractions for all age groups, including beaches, historical sites, and nature parks.`
    },
    {
      question: "10. How can I book a Turkey travel package?",
      answer: `Visit <a href="https://travelunbounded.com/" target="_blank">Travel Unbounded</a> to explore tailored travel packages for Turkey that suit your preferences.`
    }
  ];
  
const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/turkey%2Fturkey-1.webp?alt=media&token=ec6c874b-7468-412e-9a8c-d6cf0bb5d522",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/turkey%2Fturkey-2.webp?alt=media&token=96f16658-3336-4303-8a50-1ff9196d6bdc",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/turkey%2Fturkey-3.webp?alt=media&token=203b915e-403d-4c94-bfc2-a04fed9f3c2e",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/turkey%2Fturkey-4.webp?alt=media&token=5d0eff87-f57d-4071-9fc8-183512e25c21",
]

const DestinationGreece = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const himachalPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('turkey') 
      );

    return (
        <div>
        <HelmetProvider>
        <Helmet>
            <title>Discover Turkey: A Journey Through History, Culture, and Scenic Beauty</title>
            <meta
                name="description"
                content="Explore Turkey's breathtaking landscapes, historic landmarks, and vibrant culture. From Istanbul's majestic mosques to Cappadocia's magical hot air balloons, plan your dream trip to Turkey with Travel Unbounded."
                />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <Callback contactNumber="+91 9845090014"/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 turkey-container mt-5'>
                     <Fade>
                        <h1>Turkey: The Land of Wonders</h1>
                        <h2>A Magical Blend of East and West</h2>
                        <p>Explore the rich cultural tapestry, awe-inspiring landscapes, and vibrant traditions of Turkey. Whether you're drawn to ancient ruins, bustling bazaars, or stunning coastlines, Turkey offers an unforgettable experience for every traveler.</p>
                        <h2>Why Visit Turkey?</h2>
                        <ul>
                            <li><b>Cultural Diversity:</b> Turkey is where Europe meets Asia, offering a unique blend of cultural influences.</li>
                            <li><b>Ancient History:</b> Explore landmarks like the Hagia Sophia, Ephesus, and the ancient city of Troy.</li>
                            <li><b>Natural Beauty:</b> From the surreal landscapes of Cappadocia to the pristine beaches of Antalya.</li>
                            <li><b>Delicious Cuisine:</b> Savor kebabs, baklava, and traditional Turkish tea.</li>
                            <li><b>Affordable Luxury:</b> World-class experiences at reasonable prices.</li>
                        </ul>
                        <h2>Best Time to Visit Turkey</h2>
                        <ul>
                            <li><b>Spring (April to June):</b> Mild weather, perfect for sightseeing.</li>
                            <li><b>Autumn (September to November):</b> Ideal for enjoying outdoor activities and fewer crowds.</li>
                            <li><b>Summer (July to August):</b> Best for beach destinations like Antalya.</li>
                            <li><b>Winter (December to March):</b> Great for skiing in Uludağ and enjoying quieter tourist spots.</li>
                        </ul>
                        <h2>Visa Formalities</h2>
                        <p>Visitors from many countries can apply for an e-Visa online, which is a quick and convenient process. Ensure your passport is valid for at least six months from your planned arrival date. For detailed information and to apply, visit the official <a href='https://turkeyvisa.tr/home/' target='__blank'>Turkey e-Visa website</a>.</p>
                        <h2>Top Destinations in Turkey</h2>
                        <h3>1. Istanbul</h3>
                        <p>The heart of Turkey and a city of endless charm.</p>
                        <ul>
                            <li><b>Must-Visit Attractions:</b> Hagia Sophia, Blue Mosque, Topkapi Palace, Grand Bazaar.</li>
                            <li><b>Activities:</b> Cruise along the Bosphorus, enjoy a Turkish bath, and shop for unique souvenirs.</li>
                        </ul>
                        <h3>2. Cappadocia</h3>
                        <p>A surreal destination known for its fairy-tale landscapes.</p>
                        <ul>
                            <li><b>Must-Visit Attractions:</b> Goreme Open-Air Museum, underground cities, hot air balloon rides.</li>
                            <li><b>Activities:</b> Sunrise hot air balloon rides and hiking through valleys.</li>
                        </ul>
                        <h3>3. Antalya</h3>
                        <p>The jewel of the Turkish Riviera.</p>
                        <ul>
                            <li><b>Must-Visit Attractions:</b> Duden Waterfalls, Hadrian’s Gate, Lara Beach.</li>
                            <li><b>Activities:</b> Relax on beautiful beaches and explore ancient ruins.</li>
                        </ul>
                        <h3>4. Pamukkale</h3>
                        <p>Known for its white travertine terraces.</p>
                        <ul>
                            <li><b>Must-Visit Attractions:</b> Hierapolis ruins and thermal pools.</li>
                            <li><b>Activities:</b> Take a dip in the therapeutic hot springs.</li>
                        </ul>
                        <h3>5. Ephesus</h3>
                        <p>A journey into the ancient world.</p>
                        <ul>
                            <li><b>Must-Visit Attractions:</b> The Library of Celsus, Temple of Artemis, and the amphitheater.</li>
                            <li><b>Activities:</b> Explore Roman-era ruins and ancient architecture.</li>
                        </ul>
                        <h2>Things to Do in Turkey</h2>
                        <ul>
                            <li><b>Hot Air Balloon Ride in Cappadocia:</b> Witness the unique landscapes from above.</li>
                            <li><b>Turkish Hammam Experience:</b> Indulge in a rejuvenating bath.</li>
                            <li><b>Cruising the Turquoise Coast:</b> Sail through the Mediterranean’s azure waters.</li>
                            <li><b>Shop in Grand Bazaar:</b> One of the world’s oldest covered markets.</li>
                            <li><b>Taste Local Delicacies:</b> Don’t miss Turkish delights and tea.</li>
                        </ul>
                        <h2>Frequently Asked Questions(FAQ's)</h2>
                        <Faq faqData={faqData}/>
                        </Fade>
                    </div>
                </div>
            </div>
                <p className='turkey-destination-head mb-3 mt-5'>View Our Turkey Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {himachalPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </div>
    );
}

export default DestinationGreece;
