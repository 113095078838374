import React from "react";
import Blog from "../Blog";
import Footer from "../Contact";
import Navbar from "../Navbar";
import CustomerBlogCard from '../CustomerBlogCard';
import "./index.css";
import { HelmetProvider, Helmet } from 'react-helmet-async';

const BlogList = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Explore Nature's Beauty Travel Unbounded's Insights</title>
        <meta
          name="description"
          content="Dive into the captivating world of journeys through Travel unbounded's blog. Our expert insights, travel tips, and conservation stories bring you closer to nature's marvels. Stay informed, inspired, and ready for your next adventure with our tours. Explore our blog now!"
        />
      </Helmet>
      <Navbar />
     <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kerala%2Fsunset-1139293_1920.webp?alt=media&token=829f892c-fcde-47ff-8822-854e2b2e7e50'
      alt="blog"
      className="destination-img" />
      <h1 className="home-head text-center mt-5">BLOGS</h1>
      <div className="card-list">
        <Blog/>
        <CustomerBlogCard/>
      </div>
      <Footer />
    </HelmetProvider>
  );
};

export default BlogList;
