import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Callback from '../Callback';
import {Fade} from 'react-awesome-reveal';

import './index.css';
import Faq from '../Faq';

const faqData = [
  {
    question: "1. What is the best time to visit Bandhavgarh National Park?",
    answer: `The best time to visit Bandhavgarh is from October to March for pleasant weather and great wildlife sightings. For higher chances of spotting tigers, visit during the summer months (April to June) as animals are drawn to water sources.`
  },
  {
    question: "2. How can I reach Bandhavgarh National Park?",
    answer: `<strong>By Air:</strong> The nearest airport is Jabalpur Airport, around 200 km away. You can also fly to Khajuraho Airport, which is approximately 250 km from Bandhavgarh.<br>
             <strong>By Train:</strong> Umaria Railway Station (35 km away) and Katni Junction (100 km away) are the closest railway stations.<br>
             <strong>By Road:</strong> Bandhavgarh is well-connected by road from major cities like Jabalpur, Khajuraho, and other towns in Madhya Pradesh.`
  },
  {
    question: "3. How many zones are there in Bandhavgarh for safaris?",
    answer: `Bandhavgarh National Park has four main safari zones:<br>
             <strong>Tala Zone:</strong> Known for its high density of tigers.<br>
             <strong>Magadhi Zone:</strong> Offers rich wildlife sightings and diverse terrain.<br>
             <strong>Khitauli Zone:</strong> Famous for leopard sightings and birdwatching.<br>
             <strong>Panpatha Zone:</strong> A quieter zone, ideal for scenic drives and diverse wildlife viewing.`
  },
  {
    question: "4. What types of safaris are available in Bandhavgarh?",
    answer: `Visitors can opt for:<br>
             <strong>Jeep Safari:</strong> Suitable for a small group, offering an intimate wildlife viewing experience.<br>
             <strong>Canter Safari:</strong> Ideal for larger groups.<br>
             <strong>Guided Nature Walks:</strong> A closer look at the park’s flora, birds, and small fauna with a trained guide.`
  },
  {
    question: "5. Are there any restrictions during safaris?",
    answer: `Yes, to ensure a safe and respectful experience for both visitors and wildlife, you must:<br>
             - Maintain silence and avoid loud noises.<br>
             - Not feed or disturb the animals.<br>
             - Follow the instructions of your guide or driver at all times.<br>
             - Wear muted colors that blend with the surroundings.`
  },
  {
    question: "6. Can I book Bandhavgarh safari permits online?",
    answer: `Yes, it is recommended to book safari permits online in advance, especially during peak tourist seasons, to secure your spot and preferred timings.`
  },
  {
    question: "7. What should I carry during the safari?",
    answer: `Essentials include:<br>
             - Valid ID Proof for entry.<br>
             - Binoculars for better wildlife viewing.<br>
             - Camera (ensure to check camera fees, if applicable).<br>
             - Comfortable clothing in neutral colors.<br>
             - Sunscreen, hat, and sunglasses to protect from the sun.<br>
             - Water bottle and light snacks.`
  },
  {
    question: "8. How many safaris should I plan for a good wildlife experience?",
    answer: `It is recommended to book at least 2-3 safari drives across different zones to maximize your chances of spotting tigers and other wildlife.`
  },
  {
    question: "9. Are there any nearby attractions to explore?",
    answer: `Yes, apart from Bandhavgarh National Park, you can explore:<br>
             <strong>Bandhavgarh Fort:</strong> A historic fort offering spectacular views of the park.<br>
             <strong>Bari Gufa (Ancient Caves):</strong> Intriguing ancient caves scattered across the park.`
  },
  {
    question: "10. Is it safe to visit Bandhavgarh with family and children?",
    answer: `Absolutely! Bandhavgarh is a family-friendly destination. The park authorities and guides prioritize visitor safety, making it an excellent place for families and children to experience wildlife.`
  },
  {
    question: "11. What accommodations are available near Bandhavgarh?",
    answer: `The area offers a range of accommodations, from luxury resorts to jungle lodges, providing comfortable stays with modern amenities and nature-inspired experiences.`
  },
  {
    question: "12. What is the Bandhavgarh Fort?",
    answer: `The Bandhavgarh Fort is an ancient fort located within the park. Believed to be over 2,000 years old, it features historic sculptures, caves with inscriptions, and offers panoramic views of the surrounding forest.`
  },
  {
    question: "13. Is there a chance to see tigers on every safari?",
    answer: `While Bandhavgarh has a high density of tigers, sightings are not guaranteed as these are wild animals. However, the guides’ expertise and familiarity with the tigers' movements increase your chances significantly.`
  },
  {
    question: "14. Are there any unique birds to watch in Bandhavgarh?",
    answer: `Yes, Bandhavgarh is a paradise for birdwatchers with over 250 bird species, including Indian rollers, Malabar pied hornbills, crested serpent eagles, and many more.`
  },
  {
    question: "15. What are the key do’s and don’ts while visiting Bandhavgarh National Park?",
    answer: `<strong>Do’s:</strong> Follow park rules, stay seated during safaris, respect wildlife, and carry necessary items like water and ID proof.<br>
             <strong>Don’ts:</strong> Avoid making noise, do not litter, refrain from feeding animals, and avoid wearing bright colors.`
  }
];


const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bandhavgarh%2Fban-1.webp?alt=media&token=818b9fb9-3ed6-4faf-a9a7-34d05e4b2936",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bandhavgarh%2Fban-3.webp?alt=media&token=d1eafbd8-ef2d-4426-8f15-7afc48ca0d70",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bandhavgarh%2Fban-5.webp?alt=media&token=a9df1300-bdb3-447d-a708-b4dd4bcd01e7",
]

const DestinationBandhavgarh = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("WildlifePackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const keralaPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('bandhavgarh')
      );
    
    return (
        <>
         <HelmetProvider>
         <Helmet>
            <title>Explore Bandhavgarh: Wildlife Safari & Heritage in the Heart of India</title>
            <meta
                name="description"
                content="Discover the wild heart of India at Bandhavgarh National Park. Known for its dense tiger population, ancient forts, and scenic landscapes, Bandhavgarh offers unforgettable safari experiences and a glimpse into India's natural and cultural heritage. Plan your adventure today!"
                />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <Callback contactNumber="+91 9845090014" />
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 bandhavgarh-container mt-5'>
                      <Fade>
                      <h1>Bandhavgarh National Park - The Land of the Royal Bengal Tiger</h1>
                      <p>Nestled in the heart of Madhya Pradesh, Bandhavgarh National Park is synonymous with thrilling wildlife safaris and breathtaking landscapes. Known for having one of the highest densities of Royal Bengal Tigers in India, Bandhavgarh offers an unparalleled experience for nature and wildlife enthusiasts.</p>
                      <h2>Why Visit Bandhavgarh?</h2>
                      <ul>
                        <li><b>Tiger Sightings:</b> With its dense tiger population, Bandhavgarh is one of the best places to spot these majestic predators in their natural habitat.</li>
                        <li><b>Rich Biodiversity:</b> Apart from tigers, the park is home to leopards, sloth bears, wild boars, sambar deer, and numerous other wildlife species.</li>
                        <li><b>Ancient History:</b> Bandhavgarh’s history dates back over 2,000 years, featuring Bandhavgarh Fort atop a hill, surrounded by centuries-old caves and intriguing inscriptions.</li>
                        <li><b>Flora and Fauna:</b> The park's mixed forests range from tropical moist deciduous trees to grasslands, supporting diverse flora and a rich avian population.</li>
                      </ul>
                      <h2>Safari Zones in Bandhavgarh National Park</h2>
                      <ul>
                        <li><b>Tala Zone:</b> The most popular zone, known for frequent tiger sightings, rich vegetation, and beautiful landscapes.</li>
                        <li><b>Magadhi Zone:</b> A hotspot for wildlife enthusiasts with a thriving population of big cats and lush terrains.</li>
                        <li><b>Khitauli Zone:</b> Offers a great chance to spot leopards and birds and provides a quieter, off-the-beaten-path experience.</li>
                        <li><b>Panpatha Zone:</b> The buffer zone with scenic beauty and abundant wildlife sightings, including the elusive Indian wolf.</li>
                      </ul>
                      <h2>Wildlife and Attractions</h2>
                      <ul>
                        <li><b>Royal Bengal Tigers:</b> Witness these majestic animals in their prime as they roam freely.</li>
                        <li><b>Leopards & Other Predators:</b> Alongside tigers, Bandhavgarh is known for sightings of leopards, Indian wild dogs (dhole), and sloth bears.</li>
                        <li><b>Bird Watching:</b> Over 250 bird species grace the skies of Bandhavgarh, making it a paradise for bird enthusiasts.</li>
                        <li><b>Bandhavgarh Fort:</b> This historic fort provides spectacular views of the park and contains ancient statues and inscriptions.</li>
                        <li><b>Bari Gufa (Ancient Caves):</b> Explore the largest man-made caves in the park, dating back centuries.</li>
                        <li><b>Mahaman Pond:</b> A popular watering hole often visited by a variety of animals and birds.</li>
                      </ul>
                      <h2>Safari Experience</h2>
                      <ul>
                        <li><b>Jeep Safari:</b> A thrilling way to explore the park, offering close encounters with wildlife. Morning and evening safari slots are available.</li>
                        <li><b>Canter Safari:</b> Perfect for larger groups, offering a guided experience through designated park zones.</li>
                        <li><b>Guided Nature Walks:</b> Experience Bandhavgarh's flora, small fauna, and birdlife on foot with trained guides.</li>
                      </ul>
                      <h2>Best Time to Visit Bandhavgarh</h2>
                      <ul>
                        <li><b>October to March:</b> Ideal for pleasant weather, tiger sightings, and vibrant birdwatching.</li>
                        <li><b>April to June:</b> Peak time for tiger sightings due to the summer heat drawing animals to water sources.</li>
                      </ul>
                      <h2>Getting to Bandhavgarh</h2>
                      <ul>
                        <li><b>By Air:</b> The nearest airport is Jabalpur Airport, approximately 200 km away, followed by Khajuraho Airport at 250 km.</li>
                        <li><b>By Train:</b> Umaria Railway Station (35 km away) and Katni Junction (100 km) are the nearest stations.</li>
                        <li><b>By Road:</b> Bandhavgarh is well-connected by roads and can be accessed from Jabalpur, Khajuraho, or other major Madhya Pradesh cities.</li>
                      </ul>
                      <h2>Travel Tips</h2>
                      <ul>
                        <li><b>Permits:</b> Secure permits for safaris online in advance for hassle-free entry.</li>
                        <li><b>Clothing:</b> Wear muted, earthy colors to blend with the environment and avoid startling wildlife.</li>
                        <li><b>Guides:</b> Trained guides enhance the safari experience with their knowledge of animal behavior and park history.</li>
                        <li><b>Etiquette:</b>  Maintain silence during safaris, respect wildlife, and follow park rules for an immersive experience.</li>
                      </ul>
                      </Fade>
                      <h2>Frequently Asked Questions (FAQ's)</h2>
                      <Faq faqData={faqData} />
                    </div>
                </div>
            </div>
                <p className='kenya-destination-head mb-3 mt-5'>View Our Bandhavgarh Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {keralaPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <p className="title-list mb-3">{pkg.destinationHead}</p>
                            <p className="title-dates-list bold mb-3">{pkg.noOfDays}</p>
                            <p className="title-rate-list bold mb-3">{pkg.cost}</p>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/wildlife/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </>
    );
}

export default DestinationBandhavgarh;
