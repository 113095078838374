import React,{useState, useEffect} from 'react';
import './kids.css';
import Navbar from "../Navbar";
import Contact from "../Contact";
import HorizontalRow from '../HorizontalRow';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { HelmetProvider, Helmet } from 'react-helmet-async';
//import Faq from '../Faq';
import {Link} from 'react-router-dom';
import Callback from '../Callback';
import { Slide, Fade } from 'react-awesome-reveal';
import KenyaYoutube from '../KenyaYoutube';
import KenyaGallery from '../KenyaGallery';

const shortItinerary = [
    {
      title: "15th May 2025 - Boulevard Hotel ",
      paragraphs: [
        "Pick from the Airport and drive to the hotel for check in (No lunch included). Afternoon drive to Giraffe Centre and get the chance to feed the endangered Rothchild giraffes (We will have Snacks and beverages in the vehicle). Later drive back to your hotel. Dinner and overnight at te hotel on HB"
      ]
    },
    {
      title: "16th May 2025 - Sarova Lion Hill",
      paragraphs: [
        "After breakfast, pick packed lunch boxes and drive to lake Nakuru for a full day game drive. Later check in at your hotel. All meals and overnight at the hotel on FB"
      ]
    },
    {
      title: "17th May 2025 - Mara Figtree Camp (Standard)",
      paragraphs: [
        "Enjoy a morning game drive and back for breakfast and check out. Pick packed lunch boxes and drive to Masai Mara. Enjoy game drive until early evening. Check in at your hotel. All meals and overnight at the hotel on FB"
      ]
    },
    {
      title: "18th May 2025 - Mara Figtree Camp (Standard)",
      paragraphs: [
        "Unlimited game drives. All meals and overnight at the hotel on FB"
      ]
    },
    {
      title: "19th May 2025 - Boulevard Hotel",
      paragraphs: [
        "After breakfast, pick packed lunch boxes and drive to Masai Village. Later drive to Nairobi and transfer to your hotel.Dinner and overnight at the hotel on HB"
      ]
    },
    {
      title: "20th May 2025 - Depart",
      paragraphs: [
        "After breakfast, you will be transferred to the Airport for your departure flight back home."
      ]
    }
  ];

    const KenyaKids = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
      };

      const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) { 
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className='kenya-kids-container'>
        <HelmetProvider>
        <Helmet>
          <title>Magical Kenya - Best Wildlife Experiences</title>
          <meta name="description" content="Book an educational and adventurous Kenya safari for kids with Travel Unbounded. Explore wildlife, learn conservation, and more!" />
          <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-3.webp?alt=media&token=a3dc4761-1d9c-41b1-8c9d-19bfc925b640" />
          <meta property="og:title" content="Kenya Safari Tour for Kids Summer 2025" />
          <meta property="og:description" content="Join our unforgettable Kenya safari tours designed for kids aged 8-16!" />
        </Helmet>
        <Navbar/>
        <div>
        <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-3.webp?alt=media&token=a3dc4761-1d9c-41b1-8c9d-19bfc925b640' alt='kenya-kids' className='kenya-kids-img' />
        </div>
        <Callback contactNumber="+91 9845090014"/>
        <Link to='/form' className='link'><div className={`enquire-button-package-home-kenya ${isVisible ? "enquire-left-home-kenya" : ""}`}>
            <span className="button-text blink-text">Get a Quote</span>
        </div></Link>
        <div className='container kenya-kids mb-3'>
            <div className='row'>
                <div className='col-12 col-md-12 col-xl-12 col-lg-12'>
                <Fade>
                <h1 className='mt-5'>MAGICAL KENYA TOUR</h1>
                <p style={{fontStyle:'italic', fontWeight:'bold'}}>Dates 15th May 2025 to 20th May 2025</p>
                </Fade>
                <HorizontalRow/>
                </div>
                <div className='col-12 col-md-12 col-xl-12 col-lg-12'>
                  <h1 className='text-center'>Discover the Wonders of Kenya: Your Ultimate Holiday Adventure Awaits!</h1>
                <KenyaYoutube videoId="RMjm5VBcOlU"/>
                </div>
                <div className='col-12 col-md-12 col-xl-12 col-lg-12 mt-5'>
                <h1 className='text-center'>Why Travelers Keep Coming Back: The Secret Behind Our Loyal Guests and Their Recommendations</h1>
                <KenyaYoutube videoId="pRDN9DYR0UM"/>
                </div>
                <div className='col-12 col-md-12 col-lg-12 col-xl-12 mt-5'>
                <Slide direction="down" duration={500} triggerOnce>
                    <h1 className='text-center'>WHY SHOULD YOU & YOUR KIDS BE A PART OF THIS PROGRAM</h1>
                    <HorizontalRow/>
                    <ul>
                        <li>Safe off beat adventure - Experience Safe, Hygienic, and Family-Friendly Accommodations with Expert Guides at Your Service</li>
                        <li>Engage in Experiential Education: Hands-On Wildlife Conservation, Immersive Cultural Traditions, Life Lessons from Nature</li>
                    </ul>
                    </Slide>
                </div>
                <div className='col-12 col-md-12 col-xl-12 col-lg-12 mt-3'>
                <Slide direction="down" triggerOnce>
                <h1 className='text-center'>Itinerary</h1>
                </Slide>
                <HorizontalRow/>
                <div className="itinerary-container mb-5">
                {shortItinerary.map((item, index) => (
                    <div
                    key={index}
                    className={`accordion-item ${activeIndex === index ? "active" : ""}`}
                    >
                    <div className="accordion-header" onClick={() => toggleAccordion(index)}>
                        <h3>{item.title || `Day ${index + 1}`}</h3>
                        <span>{activeIndex === index ? <FaMinus size={20}/> : <FaPlus size={20}/>}</span>
                    </div>
                    {activeIndex === index && (
                        <div className="accordion-body justify">
                        {item.paragraphs.map((paragraph, pIndex) => (
                            <p key={pIndex}>{paragraph}</p>
                        ))}
                        </div>
                    )}
                    </div>
                ))}
                </div>
                </div>
                <h1 className='text-center'>Adventure Awaits: A Magical Itinerary to Kenya!</h1>
              <KenyaYoutube videoId="qSR_lZb1M7g"/>
            <div className='col-12 col-md-6 col-lg-6 col-xl-6 mt-5'>
            <Slide direction="left" triggerOnce>
                    <h1>Inclusions</h1>
                    <HorizontalRow/>
                    <ul className='kenya-list'>
                        <li>Meals & Accommodations in safari lodges on full board basis </li>
                        <li>Transport in NON-AC 4x4 Land cruiser as per quote on private basis with pop up roof for easy game viewing </li>
                        <li>Services of our Certiﬁed Driver Guide </li>
                        <li>Drinking water on tap in our vehicle while on Safari</li>
                        <li>Park Entrance Fees as per current rates (Incase next year’s park fees go up, we will advise in good time). </li>
                        <li>Game drives and activities as per itinerary </li>
                        <li>Travel Insurance of USD 50,000 per participant </li>
                        <li>Flying Doctor’s evacuation fee temporary membership </li>
                        <li>Medical kit and goodie bag from Travel Unbounded</li>
                        <li>International airfare from Chennai or Bangalore to Nairobi</li>
                        <li>VISA Charges</li>
                        <li>Meet & Greet at Nairobi airport on arrival</li>
                        <li>Flying Doctors insurance during the tour</li>
                    </ul>
                    </Slide>
                </div>
                <div className='col-12 col-md-6 col-lg-6 col-xl-6 mt-5'>
                <Slide direction='right' duration={2000} triggerOnce>
                    <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-6.webp?alt=media&token=bff6e44a-b137-4beb-ae86-84a27a478e42' alt='inclusion-img' className='inclusion-img'/>
                    </Slide>
                </div>
                <HorizontalRow/>

                <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
                <Slide direction='left' duration={2000} triggerOnce>
                    <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-9.webp?alt=media&token=af8af22d-5781-4e4f-afe1-1985672964d9' alt='kenya-kids-tour' className='exclusion-img-kenya'/>
                </Slide>
                </div>
                <div className='col-12 col-md-6 col-lg-6 col-xl-6 mt-5'>
                <Slide direction="right" triggerOnce>
                    <h1>Exclusions</h1>
                    <HorizontalRow/>
                    <ul className='kenya-list'>
                        <li>Balloon Safari ($ 550 per person) </li>
                        <li>All Optional Activities extras not stated including entry fees at Nairobi </li>
                        <li>Expenses of personal nature such as telecommunications, tips / gratuities,ETC  </li>
                        <li>Laundry services not unless otherwise mentioned </li>
                        <li>Camera, binoculars, bean bags</li>
                        <li>Changes or deviation from the itinerary </li>
                        <li>Any other item/s not speciﬁed above</li>
                    </ul>
                    </Slide>
                </div>
                <HorizontalRow/>
                <Fade> 
                <p className='text-center' style={{fontStyle:'italic', fontWeight:'bold'}}>Special Offer: Valid for bookings made on or before 15th January 2025</p>
                <div className='cost-span-row'>
                <div className='cost-span-container'>
                <h1 className='cost-head-kenya'>Cost Ex Chennai or Ex Bangalore</h1>
                <h4 className='cost-kenya'><span className='cost-span'>12 to 17 years -</span> USD 1842 per person</h4>
                <h4 className='cost-kenya'><span className='cost-span'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;18+ years - </span>USD 1967 per person</h4>
                <p style={{fontWeight:'bold'}}>*Group Fare Price, group size min 6 paxs</p>
                </div>
                </div>
                </Fade>
                <HorizontalRow/>
                <h1 className='text-center'>Cancellation Policy</h1>
                <HorizontalRow/>
                <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
                <Fade>
                  <ul>
                    <li> Any non-refundable deposits paid to the hotels or airline tickets purchased, with prior notiﬁcation to the client or agent will not be refunded</li>
                    <li> Where no such deposits have been made, the below applies: </li>
                    <li> Cancellation prior to 60/90 days (depending with season) – only bank charges will be deducted</li>
                    <li> Cancellations received less than 60/90 days prior to departure will be subject to the following</li>
                    <li> Low Season: </li>
                    <li> Less than 60 days 25%</li>
                    <li> Less than 45 days is 50%</li>
                    <li> Less than 15 days is 100%</li>
                    <li> Beyond 60 days – Full refund except bank charges (including service fees) and non-refundable deposits (if any) at the hotels and air tickets</li>
                    <li> Seats would be allocated on ﬁrst come ﬁrst basis </li>
                    <li> All passports must be valid for 6 months after departure and have at least 2 empty pages </li>
                    <li> Visa to Kenya is only available online. Please apply at <a href='http://evisa.go.ke/eligibility.html' target='__blank'>Kenya visa</a></li>
                    <li> Masai Mara park ticket validity has been revised effective 1st July to 6 AM – 6 PM thus disallowing game drives on the date of departure</li>
                    <li> All quotations are based on current Government Park Tariffs. Park fees are subject to change without prior notice.</li>
                  </ul>
                  </Fade>
                </div>
                <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
                  <h1 className='text-center'>Our Collection of Captured Magic</h1>
                  <KenyaGallery/>
                  <div className='book-btn-kenya-container'>
                    <Link to='/form'>
                    <button className='book-btn-kenya nav-btn-tu-animate'>Book Now</button>
                    </Link>
                  </div>
                </div>
            </div>  
          </div>  
          <div className="image-container-kenya-kids">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-5.webp?alt=media&token=76ff5f20-1dac-4d53-a77b-60e916d00346"
              alt="africa-safari"
              className="kenya-kids-img"
            />
            <div className="overlay-text-kenya-kids">
              <p>“Africa changes you forever, like nowhere on Earth. Once you have been there, you will never be the same.” 
              </p>
              <p>–Brian Jackman</p>
            </div>
          </div>
        <Contact/>
        </HelmetProvider>
        </div>
    );
}

export default KenyaKids;
