import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {Fade} from 'react-awesome-reveal';
import Callback from '../Callback';
import Faq from '../Faq';
import './index.css';

const faqData = [
    {
      question: "1. Why is Nepal a popular travel destination?",
      answer: `Nepal is famous for its Himalayan treks, including Everest Base Camp, its rich culture, and spiritual experiences at places like Lumbini (the birthplace of Lord Buddha) and Kathmandu Valley.`
    },
    {
      question: "2. What is the best time to visit Nepal?",
      answer: `The best time to visit Nepal is during the autumn season (September to November) and spring (March to May) for clear skies and pleasant trekking conditions.`
    },
    {
      question: "3. What are the top activities to do in Nepal?",
      answer: `Trekking in the Annapurna Circuit or Everest Base Camp. Jungle safaris in Chitwan National Park. Cultural exploration in Kathmandu, Bhaktapur, and Patan. Adventure sports like paragliding, white-water rafting, and bungee jumping.`
    },
    {
      question: "4. Is Nepal suitable for family vacations?",
      answer: `Yes, Nepal offers activities for all ages, including cultural tours, easy treks, and wildlife safaris.`
    },
    {
      question: "5. Do I need a visa to travel to Nepal?",
      answer: `Most nationalities require a visa, which can be obtained on arrival at Tribhuvan International Airport or through Nepali embassies worldwide.`
    },
    {
      question: "6. Is Nepal safe for solo travelers?",
      answer: `Nepal is considered one of the safest countries for solo travelers. However, trekkers should always hire guides for remote trails.`
    },
    {
      question: "7. What is the local currency in Nepal?",
      answer: `The currency is the Nepalese Rupee (NPR). Indian Rupees are widely accepted in smaller denominations.`
    },
    {
      question: "8. How do I travel within Nepal?",
      answer: `Travel options include domestic flights, tourist buses, and private vehicles. Roads can be rugged in remote areas.`
    }
  ];
  

const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/nepal%2Fnepal-1.webp?alt=media&token=9bc8966b-d1de-45a0-a02c-f288bdcc9212",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/nepal%2Fnepal-3.webp?alt=media&token=8f281973-532d-4a0d-afd4-be10030b9e1c",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/nepal%2Fnepal-2.webp?alt=media&token=d54be61c-b724-4a85-8635-229dd02bbb8d",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/nepal%2Fnepal-4.webp?alt=media&token=d2f34cd6-adff-425a-8d03-04b4629937a6"
]

const DestinationNepal = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const himachalPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('nepal') ||
        pkg.destinationName.toLowerCase().includes('kathmandu')
      );

    return (
        <div>
        <HelmetProvider>
        <Helmet>
            <title>Explore Nepal: The Ultimate Travel Destination for Culture, Adventure & Nature</title>
            <meta
                name="description"
                content="Discover the best of Nepal, from trekking the Himalayas to exploring ancient temples in Kathmandu. Experience rich culture, adventure, and natural beauty in this stunning Himalayan destination. Plan your Nepal journey now!"
                />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <Callback contactNumber="+91 9141001434"/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 nepal-container mt-5'>
                        <Fade>
                        <h1>Discover the Wonders of Nepal: A Comprehensive Guide to the “Land of the Himalayas”</h1>
                        <p>Nepal, a gem nestled in the heart of the Himalayas, offers breathtaking landscapes, rich cultural heritage, and a diverse array of experiences for every traveler. Whether you're seeking an adventurous trek, a spiritual journey, or a peaceful retreat, Nepal provides an enchanting blend of nature, tradition, and modern amenities, making it an ideal destination for tourists worldwide.</p>
                        <h2>Geography of Nepal: Where Adventure Meets Serenity</h2>
                        <p>Nepal’s geography is dominated by the majestic Himalayas, including the world's highest peak, Mount Everest. From snow-capped mountains to lush valleys and serene lakes, Nepal's diverse landscapes offer a paradise for nature lovers and adventure enthusiasts alike.</p>
                        <h2>Airports in Nepal: Your Gateway to Himalayan Adventures</h2>
                        <p>Nepal is well-connected to the world through its major international airport, ensuring easy access for both domestic and international travelers.</p>
                        <ul>
                            <li><b>Tribhuvan International Airport (TIA):</b><br/>Located in Kathmandu, Tribhuvan International Airport is Nepal's primary international gateway. It connects the country to major destinations across Asia, the Middle East, and beyond, serving as the hub for both tourism and business travelers.</li>
                            <li><b>Gautam Buddha International Airport (GBIA):</b><br/>Situated near Lumbini, the birthplace of Lord Buddha, this newly inaugurated airport enhances connectivity, especially for spiritual tourists seeking to explore Nepal’s rich Buddhist heritage.</li>
                        </ul>
                        <h2>Climate of Nepal: Diverse Weather Patterns</h2>
                        <p>Nepal experiences a wide range of climates due to its diverse topography, from tropical lowlands to alpine heights. The country has four distinct seasons: spring, summer, autumn, and winter, with the monsoon season from June to September bringing heavy rains that rejuvenate the landscape. Autumn (September to November) is considered the best time to visit, offering clear skies and pleasant temperatures.</p>
                        <h2>Culture of Nepal: A Tapestry of Traditions</h2>
                        <p>Nepal’s rich cultural heritage reflects its diverse ethnic groups and centuries-old traditions. The country is known for its vibrant festivals, including Dashain, Tihar, and Holi, along with spiritual practices rooted in Hinduism and Buddhism. Nepal’s traditional arts, music, and cuisine offer a deep, immersive experience for visitors.</p>
                        <h2>Visa Information for Foreign Nationals</h2>
                        <p>Foreign travelers can obtain a visa upon arrival in Nepal at Tribhuvan International Airport or at designated border crossings. Ensure you have the correct documentation before traveling to Nepal to avoid any issues.</p>
                        <h2>Unique Nepal Experiences: Exploring the Top Destinations</h2>
                        <p>Nepal is full of must-visit destinations offering a mix of adventure, spirituality, and natural beauty. Here are some highlights that capture the essence of this magical land:</p>
                        <h3>1. Trekking in Nepal
                        </h3>
                        <ul>
                            <li><b>Everest Base Camp Trek:</b><br/>One of the most iconic treks in the world, the Everest Base Camp Trek offers breathtaking views of the world’s tallest mountain, Mount Everest. Experience the Sherpa culture and visit famous sites like Namche Bazaar and Tengboche Monastery along the way.</li>
                            <li><b>Annapurna Circuit:</b><br/>This trek takes you through diverse landscapes, from subtropical forests to alpine meadows, offering incredible views of the Annapurna range. The journey also includes a visit to the famous town of Pokhara and the sacred Muktinath Temple.</li>
                        </ul>
                        <h3>2. Spiritual and Cultural Experiences in Nepal</h3>
                        <ul>
                            <li><b>Lumbini:</b><br/>The birthplace of Lord Buddha, Lumbini is a UNESCO World Heritage Site and a pilgrimage destination for Buddhists from around the world. Explore ancient stupas, monasteries, and the peaceful Mayadevi Temple.</li>
                            <li><b>Kathmandu Valley:</b><br/>Home to seven UNESCO World Heritage Sites, the Kathmandu Valley boasts ancient temples, palaces, and stupas. Must-visit sites include Swayambhunath (the Monkey Temple), Pashupatinath Temple, and Boudhanath Stupa.</li>
                        </ul>
                        <h3>3. National Parks and Wildlife in Nepal</h3>
                        <ul>
                            <li><b>Chitwan National Park:</b><br/>A UNESCO World Heritage Site, Chitwan National Park is a haven for wildlife enthusiasts. Take a jungle safari to spot Bengal tigers, one-horned rhinoceroses, and a variety of bird species.</li>
                            <li><b>Sagarmatha National Park:</b><br/>Situated in the Everest region, this park is home to a wide range of wildlife, including snow leopards, red pandas, and Himalayan tahr. The park also offers stunning views of Mount Everest and other towering peaks.</li>
                        </ul>
                        <h3>4. Pristine Lakes of Nepal</h3>
                        <ul>
                            <li><b>Phewa Lake, Pokhara:</b><br/>Nestled in the foothills of the Annapurna range, Phewa Lake offers serene boat rides and stunning views of the mountains. Pokhara itself is a hub for adventure activities like paragliding and ultralight flights.</li>
                            <li><b>Rara Lake:</b><br/>The largest lake in Nepal, Rara Lake is located in the remote northwestern part of the country. Its crystal-clear waters are surrounded by lush forests, offering a tranquil escape from the hustle and bustle of city life.</li>
                        </ul>
                        <h3>5. Adventure Activities in Nepal</h3>
                        <ul>
                            <li><b>Mountaineering and Climbing:</b><br/>Nepal is the ultimate destination for mountaineers, with peaks like Mount Everest, Lhotse, and Manaslu attracting climbers from around the world. Whether you're an experienced climber or a novice, there are expeditions and courses to suit all skill levels.</li>
                            <li><b>White-Water Rafting:</b><br/>Nepal’s rivers, fed by glaciers, offer some of the best white-water rafting experiences in the world. The Trishuli River, Bhote Koshi, and Karnali River provide exhilarating rapids for both beginners and seasoned rafters.</li>
                        </ul>
                        <h3>6. Local Markets and Crafts in Nepal</h3>
                        <ul>
                            <li><b>Thamel, Kathmandu:</b><br/>Thamel is the main tourist hub in Kathmandu, known for its bustling streets filled with shops selling handicrafts, trekking gear, and traditional Nepali goods. It’s a great place to buy souvenirs like prayer flags, Thangka paintings, and Pashmina shawls.</li>
                            <li><b>Patan Durbar Square:</b><br/>Located in the city of Lalitpur, Patan Durbar Square is known for its exquisite Newari architecture and vibrant markets where you can purchase traditional handmade crafts and jewelry.</li>
                        </ul>
                        <h3>7. Ayurvedic Wellness in Nepal</h3>
                        <ul>
                            <li><b>Kathmandu & Pokhara:</b><br/>Nepal is gaining popularity as a wellness destination, offering a variety of Ayurvedic treatments and yoga retreats. These holistic therapies provide a blend of relaxation and spiritual healing, ideal for rejuvenating both body and mind.</li>
                        </ul>
                        <h3>8. Paragliding in Nepal</h3>
                        <ul>
                            <li><b>Sarangkot, Pokhara:</b><br/>Experience the thrill of paragliding over the Pokhara Valley with breathtaking views of Phewa Lake and the Annapurna mountain range. Sarangkot is the prime location for this adventure, attracting paragliders from around the globe.</li>
                        </ul>
                        <h2>Frequently Asked Questions(FAQ's)</h2>
                        <Faq faqData={faqData}/>
                        </Fade>
                    </div>
                </div>
            </div>
                <p className='nepal-destination-head mb-3 mt-5'>View Our Nepal Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {himachalPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </div>
    );
}

export default DestinationNepal;
