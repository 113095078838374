import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {Fade} from 'react-awesome-reveal';
import Callback from '../Callback';
import './index.css';
import Faq from '../Faq';

const faqData = [
    {
      question: "1. What is the best time to visit Greece?",
      answer: `The best time to visit Greece is from April to October, with peak tourist months being June through August. For pleasant weather and fewer crowds, plan your trip in spring (April-May) or early autumn (September-October).`
    },
    {
      question: "2. Do I need a visa to travel to Greece?",
      answer: `Visa requirements depend on your nationality. Citizens of the EU, USA, Canada, and Australia can stay for up to 90 days without a visa. Check with the Greek embassy or consulate for specific requirements.`
    },
    {
      question: "3. What are the must-visit destinations in Greece?",
      answer: `Top destinations include:<br>
               - <strong>Athens</strong>: Explore ancient landmarks like the Acropolis.<br>
               - <strong>Santorini</strong>: Famous for sunsets and caldera views.<br>
               - <strong>Mykonos</strong>: Known for its nightlife and beaches.<br>
               - <strong>Crete</strong>: A blend of history, nature, and local culture.<br>
               - <strong>Delphi</strong>: A UNESCO World Heritage Site steeped in mythology.`
    },
    {
      question: "4. What is the local currency, and can I use credit cards?",
      answer: `The currency is the Euro (€). Credit cards are widely accepted in major cities, islands, and tourist areas, but it’s advisable to carry cash for smaller towns and markets.`
    },
    {
      question: "5. How do I get around in Greece?",
      answer: `- <strong>In cities</strong>: Public buses, taxis, and metro (in Athens).<br>
               - <strong>On islands</strong>: Scooters, rental cars, or local buses.<br>
               - <strong>Between islands</strong>: Ferries are the most common mode of transport, with flights available for larger islands.`
    },
    {
      question: "6. What are Greece's top family-friendly activities?",
      answer: `- Exploring the ancient ruins of Athens.<br>
               - Swimming and playing on the beaches of Crete or Naxos.<br>
               - Visiting the Natural History Museum in Heraklion.<br>
               - Taking a boat ride around Santorini’s caldera.`
    },
    {
      question: "7. Is Greece safe for tourists?",
      answer: `Yes, Greece is generally considered safe for travelers. However, exercise standard precautions, especially in crowded tourist areas, to avoid pickpocketing.`
    },
    {
      question: "8. What foods should I try in Greece?",
      answer: `Must-try Greek dishes include:<br>
               - <strong>Moussaka</strong>: Layers of eggplant, minced meat, and béchamel sauce.<br>
               - <strong>Gyros</strong>: Grilled meat wrapped in pita with tzatziki.<br>
               - <strong>Spanakopita</strong>: Spinach pie with feta cheese.<br>
               - <strong>Baklava</strong>: A sweet pastry made of layers of filo dough and honey.`
    },
    {
      question: "9. Do people in Greece speak English?",
      answer: `Yes, English is widely spoken, especially in tourist areas, major cities, and islands. Learning a few Greek phrases like "Kalimera" (Good morning) or "Efharisto" (Thank you) is appreciated.`
    },
    {
      question: "10. What should I pack for a trip to Greece?",
      answer: `Essentials include:<br>
               - Light clothing for summer.<br>
               - Comfortable walking shoes for exploring ruins and towns.<br>
               - Swimwear for the beaches.<br>
               - A light jacket for cooler evenings in spring or autumn.`
    },
    {
      question: "11. Are tips expected in Greece?",
      answer: `Tipping is appreciated but not mandatory. At restaurants, rounding up the bill or leaving 5-10% is customary. For taxis, rounding up the fare is common.`
    },
    {
      question: "12. Can I drink tap water in Greece?",
      answer: `Tap water is safe in most mainland areas but may not be potable on some islands. Stick to bottled water if in doubt.`
    }
  ];
  

const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/greece%2Fgreece-2.webp?alt=media&token=d021b05e-c7b1-4b12-848a-aca3998cb102",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/greece%2Fgreece-4.webp?alt=media&token=4b2bc011-16a8-47d9-ab4f-9e85bf9a4d89",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/greece%2Fgreece-1.webp?alt=media&token=e8f9329c-7f4d-4847-bcf8-77622aaa0045",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/greece%2Fgreece-3.webp?alt=media&token=513b7122-80a1-42a0-a52c-e87d28be5e30",
]

const DestinationGreece = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const himachalPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('greece') ||
        pkg.destinationName.toLowerCase().includes('greek')
      );

    return (
        <div>
        <HelmetProvider>
        <Helmet>
            <title>Explore Greece: A Land of Myth, History, and Scenic Beauty</title>
            <meta
                name="description"
                content="Plan your dream trip to Greece and discover iconic landmarks like the Acropolis, idyllic islands like Santorini and Mykonos, and delicious Mediterranean cuisine. Explore an unforgettable Greek adventure!"
                />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <Callback contactNumber="+91 9845090014"/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 greece-container mt-5'>
                     <Fade>
                        <h1>Discover Greece with Travel Unbounded</h1>
                        <h2>Why Visit Greece?</h2>
                        <p>Greece offers a harmonious blend of ancient history, stunning landscapes, and vibrant culture. From the iconic ruins of Athens to the sun-drenched Greek islands, every corner of Greece promises unforgettable experiences.</p>
                        <h2>Best Time to Visit</h2>
                        <p>The ideal months to visit Greece are from April to October. Spring and early autumn provide pleasant weather and fewer crowds, while summer offers a lively atmosphere on the islands.</p>
                        <h2>Visa Formalities</h2>
                        <ul>
                            <li><b>Visa Type:</b> Schengen Tourist Visa.</li>
                            <li><b>Requirements:</b> Valid passport (at least 6 months validity), Completed visa application form, Recent passport-sized photographs, Cover letter stating travel purpose.</li>
                            <li><b>Application Process:</b> Apply through <a href='https://www.vfsglobal.com/en/individuals/index.html' target='__blank'>VFS Global</a> (authorized partner for Greece visas in India).</li>
                        </ul>
                        <h2>Top Destinations in Greece</h2>
                        <ul>
                            <li><b>1. Athens</b><br/>Walk through the cradle of Western civilization, exploring landmarks like the Acropolis, Parthenon, and Plaka neighborhood.</li>
                            <li><b>2. Santorini</b><br/>Famous for its white-washed architecture and breathtaking sunsets, Santorini is a romantic paradise with volcanic beaches.</li>
                            <li><b>3. Mykonos</b><br/>Renowned for its cosmopolitan nightlife, charming windmills, and pristine beaches, Mykonos is perfect for leisure seekers.</li>
                            <li><b>4. Crete</b><br/>Greece's largest island offers a mix of rugged landscapes, ancient ruins, and serene villages. Don't miss the Palace of Knossos.</li>
                            <li><b>5. Delphi</b><br/>Immerse yourself in mythology at this UNESCO World Heritage Site, considered the center of the ancient Greek world.</li>
                        </ul>
                        <h2>Top Activities</h2>
                        <ul>
                            <li><b>Historical Tours:</b> Explore ancient ruins like the Acropolis in Athens or the Oracle of Delphi.</li>
                            <li><b>Island Hopping:</b> Sail across the Aegean to islands like Mykonos, Santorini, and Naxos.</li>
                            <li><b>Culinary Delights:</b> Relish authentic Greek dishes, from moussaka to freshly caught seafood.</li>
                            <li><b>Beaches & Water Sports:</b> Relax on Santorini’s volcanic beaches or enjoy scuba diving in Crete.</li>
                            <li><b>Sunset Views:</b> Witness mesmerizing sunsets in Oia, Santorini.</li>
                        </ul>
                        <h2>Travel Tips</h2>
                        <ul>
                            <li><b>Currency:</b> Euro (€)</li>
                            <li><b>Language:</b> Greek, though English is widely spoken in tourist areas.</li>
                            <li><b>Transportation:</b> Ferries connect most islands, and local buses are affordable for inland travel.</li>
                            <li><b>Local Delights:</b> Don’t miss trying spanakopita, baklava, and locally produced olive oil.</li>
                        </ul>
                        <h3>Plan Your Trip</h3>
                        <p>Our tailored packages ensure a stress-free Greek adventure:</p>
                        <ul>
                            <li><b>Greece Romance Escape (6N/7D)</b> Perfect for history buffs and romantics.</li>
                            <li><b>Greece Heritage Odyssey (8N/9D)</b> Combine Delphi, Athens, and Meteora for a deep dive into Greek history.</li>
                            <li><b>Greek Island Explorer (9N/10D)</b> Visit Mykonos, Santorini, and Crete.</li>
                        </ul>
                        <p>Customize your trip with our flexible itineraries, and enjoy exclusive deals and guided tours.</p>
                        <h1>Frequently Asked Questions(FAQ's)</h1>
                        <Faq faqData={faqData}/>
                        </Fade>
                    </div>
                </div>
            </div>
                <p className='greece-destination-head mb-3 mt-5'>View Our Greece Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {himachalPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </div>
    );
}

export default DestinationGreece;
