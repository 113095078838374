import React from 'react';
import './index.css';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { toast, Toaster } from "react-hot-toast";

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Sidebar = ({ isSidebarOpen, setActiveComponent, setIsSidebarOpen, activeComponent }) => {
  const handleItemClick = (component) => {
    setActiveComponent(component);
    setIsSidebarOpen(false);
  };

  const handleConfirmLogout = () => {
    firebase.auth().signOut()
        .then(() => {
            console.log('Logged Out Successfully');
            toast.success("Logged Out Successfully");
        })
        .catch(error => {
            console.error('Error logging out:', error);
            toast.error("Logout Failed");
        });
  };

  return (
    <>
      <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
      <div className={`sidebar-dashboard ${isSidebarOpen ? 'open' : ''}`}>
        {isSidebarOpen && (
          <button className="close-sidebar-dashboard" onClick={() => setIsSidebarOpen(false)}>
            ✕
          </button>
        )}
        <div className="sidebar-logo-dashboard">
          <img src="/logo.png" alt="Logo" onClick={() => handleItemClick('HomeDashboard')}/>
        </div>
        <ul>
          <li
            className={activeComponent === 'HomeDashboard' ? 'active' : ''}
            onClick={() => handleItemClick('HomeDashboard')}
          >
            Dashboard Home
          </li>
          <li
            className={activeComponent === 'AllBookings' ? 'active' : ''}
            onClick={() => handleItemClick('AllBookings')}
          >
            View Bookings
          </li>
          <li
            className={activeComponent === 'AllEnquiries' ? 'active' : ''}
            onClick={() => handleItemClick('AllEnquiries')}
          >
            View Enquiries
          </li>
          <li
            className={activeComponent === 'QuizResponses' ? 'active' : ''}
            onClick={() => handleItemClick('QuizResponses')}
          >
            View Quiz Responses
          </li>
          <li
            className={activeComponent === 'DestinationTemplate' ? 'active' : ''}
            onClick={() => handleItemClick('DestinationTemplate')}
          >
            Add Destination
          </li>
          <li
            className={activeComponent === 'AddPackages' ? 'active' : ''}
            onClick={() => handleItemClick('AddPackages')}
          >
            Add Leisure Packages
          </li>
          <li
            className={activeComponent === 'AddWildlifePackages' ? 'active' : ''}
            onClick={() => handleItemClick('AddWildlifePackages')}
          >
            Add Wildlife Packages
          </li>
          <li
            className={activeComponent === 'AddMediumBlog' ? 'active' : ''}
            onClick={() => handleItemClick('AddMediumBlog')}
          >
            Add Medium Blog
          </li>
          <li
            className={activeComponent === 'AddCustomerBlog' ? 'active' : ''}
            onClick={() => handleItemClick('AddCustomerBlog')}
          >
            Add Customer Blog
          </li>
          <li
            className={activeComponent === 'AddQuiz' ? 'active' : ''}
            onClick={() => handleItemClick('AddQuiz')}
          >
            Add Quiz
          </li>
        </ul>
        <button className="logout-btn-dashboard" onClick={handleConfirmLogout}>Logout</button>
      </div>
    </>
  );
};

export default Sidebar;