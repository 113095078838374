import React from "react";
import './index.css';

const ResponsiveYouTubeEmbed = ({ videoId }) => {
  return (
    <div className="video-container-kenya">
      <div className="youtube-video-wrapper">
        <iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder='0'
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default ResponsiveYouTubeEmbed;
