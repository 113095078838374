import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {Fade} from 'react-awesome-reveal';
import Callback from '../Callback';
import './index.css';
import Faq from '../Faq';

const faqData = [
  {
    question: "1. What is Dubai famous for?",
    answer: `Dubai is renowned for its modern architecture, luxurious lifestyle, and attractions like the Burj Khalifa, Palm Jumeirah, and desert safaris.`
  },
  {
    question: "2. Is Dubai suitable for family vacations?",
    answer: `Absolutely! Dubai offers family-friendly attractions like Legoland, Aquaventure Waterpark, and the Dubai Aquarium.`
  },
  {
    question: "3. How do I apply for a Dubai visa?",
    answer: `Most travelers can apply for a tourist visa online or through their airline. Visa-on-arrival is available for select nationalities.`
  },
  {
    question: "4. What is the best time to visit Dubai?",
    answer: `November to March is the best time to visit, with mild weather perfect for exploring.`
  },
  {
    question: "5. Can I experience traditional culture in Dubai?",
    answer: `Yes, visit Al Fahidi Historical Neighborhood, attend a desert safari, or enjoy Emirati cuisine at Al Fanar Restaurant.`
  },
  {
    question: "6. What are the best shopping places in Dubai?",
    answer: `Dubai Mall for luxury brands, Mall of the Emirates for variety, and traditional souks for unique finds.`
  },
  {
    question: "7. Is Dubai safe for tourists?",
    answer: `Dubai is one of the safest cities in the world, with low crime rates and a welcoming atmosphere for travelers.`
  },
  {
    question: "8. What are must-try foods in Dubai?",
    answer: `Try shawarma, falafel, Al Harees, and Emirati desserts like Luqaimat.`
  },
  {
    question: "9. How many days are enough to explore Dubai?",
    answer: `A 5-7 day trip is ideal to cover major attractions and enjoy a mix of activities.`
  },
  {
    question: "10. Can I drink alcohol in Dubai?",
    answer: `Alcohol is served in licensed establishments like hotels, bars, and restaurants. Public consumption is prohibited.`
  }
];

  
const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/dubai%2Fdubai-1.webp?alt=media&token=0e2454d4-9391-4c24-8f6f-7377a1c78bcc",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/dubai%2Fdubai-2.webp?alt=media&token=30ec6386-3ffd-4634-9391-20949d6fe33d",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/dubai%2Fdubai-3.webp?alt=media&token=7cb4dd9c-2521-4344-a9f9-8d184c184100",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/dubai%2Fdubai-4.webp?alt=media&token=ae987456-685e-4914-ad17-db4248677b98",
]

const DestinationDubai = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const himachalPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('dubai') 
      );

    return (
        <div>
        <HelmetProvider>
        <Helmet>
            <title>Explore Dubai: Luxury, Adventure, and Culture | Travel Unbounded</title>
            <meta
                name="description"
                content="Discover Dubai's iconic attractions like the Burj Khalifa, Palm Jumeirah, and desert safaris. Plan your perfect trip with family-friendly activities, world-class shopping, and traditional Emirati experiences."
                />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <Callback contactNumber="+91 9141001434"/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 dubai-container mt-5'>
                     <Fade>
                        <h1>Discover Dubai: The Jewel of the Middle East</h1>
                        <h2>Welcome to Dubai – A City of Dreams</h2>
                        <p>Dubai, the epitome of luxury, adventure, and modern marvels, offers a unique blend of tradition and futuristic experiences. From iconic skyscrapers to golden deserts, Dubai is a destination that caters to every kind of traveler.</p>
                        <h2>Why Choose Dubai as Your Next Travel Destination?</h2>
                        <ul>
                          <li><b>Architectural Wonders:</b> Home to the world’s tallest building, the Burj Khalifa, and stunning landmarks like the Burj Al Arab.</li>
                          <li><b>Shopping Paradise:</b> Dubai Mall and the traditional souks offer an unparalleled shopping experience.</li>
                          <li><b>Cultural Richness:</b> Explore the heritage of Dubai at Al Fahidi Historical Neighborhood and the Dubai Museum.</li>
                          <li><b>Thrilling Adventures:</b> Desert safaris, skydiving, and dune bashing await adrenaline seekers.</li>
                          <li><b>Family-Friendly Attractions:</b> Aquariums, theme parks, and beaches make Dubai perfect for all ages.</li>
                        </ul>
                        <h2>Best Time to Visit Dubai</h2>
                        <ul>
                          <li><b>Winter (November to March):</b> Ideal for outdoor activities and sightseeing, with pleasant weather.</li>
                          <li><b>Summer (June to September):</b> Best for budget travelers, with discounts on hotels and attractions.
                          </li>
                        </ul>
                        <h2>Visa Formalities</h2>
                        <ul>
                          <li><b>Eligibility:</b> Most travelers need a tourist visa; some nationalities are eligible for visa-on-arrival.</li>
                          <li><b>Duration:</b> Tourist visas are generally issued for 30 or 90 days.</li>
                          <li><b>Documents:</b> Passport with at least 6 months validity, a passport-size photo, and travel details.</li>
                          <li><b>Online:</b> Apply through the official UAE <a href='https://www.uaevisa.ae/apply-visa?action=step1&gad_source=1&gclid=CjwKCAiA6aW6BhBqEiwA6KzDcyOKiEnOKSkkgDJszaiy_ZCF9oxtjRkoM4WBLzvQctHs-qO29_EIkRoCzkwQAvD_BwE' target='__blank'>e-Visa portal</a> or <a href='https://www.instadubaivisa.ae/uae/visa.php?url=https://www.instadubaivisa.ae/uae/visa.php&matype=b&dev=c&devmod=&net=g&campid=21271677544&adid=162903114158&key=gdrfa&creat=718249380442&gad_source=1&gclid=CjwKCAiA6aW6BhBqEiwA6KzDc59mtMJgY4D6pcvowKPIZb-XSibHezksxbTQ2iEF4fvQTt2CUh2eMBoCHt4QAvD_BwE' target='__blank'>GDRFA website</a>.</li>
                        </ul>
                        <h2>Top Attractions in Dubai</h2>
                        <h3>1. Burj Khalifa</h3>
                        <p>Standing tall at 828 meters, the Burj Khalifa offers breathtaking views from its observation decks.</p>
                        <ul>
                          <li><b>Timings:</b> 8:30 AM – 10:00 PM</li>
                          <li><b>Tip:</b> Visit during sunset for spectacular views.</li>
                        </ul>
                        <h3>2. The Dubai Mall</h3>
                        <p>More than just a shopping destination, it’s a hub for entertainment with an aquarium, ice rink, and cinemas.</p>
                        <ul>
                          <li><b>Must-Visit Spots:</b> Dubai Aquarium, VR Park, and the Fashion Avenue.</li>
                        </ul>
                        <h3>3. Palm Jumeirah</h3>
                        <p>An artificial archipelago shaped like a palm tree, offering luxury resorts and fine dining.</p>
                        <ul>
                          <li><b>Activities:</b> Relax at Atlantis The Palm or indulge in water sports.</li>
                        </ul>
                        <h3>4. Dubai Desert Safari</h3>
                        <p>Experience the mystique of the Arabian desert.</p>
                        <ul>
                          <li><b>Activities:</b> Dune bashing, camel rides, sandboarding, and traditional Bedouin-style dinners.</li>
                        </ul>
                        <h3>5. Dubai Miracle Garden</h3>
                        <p>A floral wonderland featuring over 150 million flowers arranged in creative designs.</p>
                        <ul>
                          <li><b>Best Time to Visit:</b> November to March.</li>
                        </ul>
                        <h2>Unique Experiences in Dubai</h2>
                        <ul>
                          <li><b>Luxury Yacht Cruise:</b> Enjoy Dubai’s skyline from the sea.</li>
                          <li><b>Dinner in the Sky:</b> Dine suspended 50 meters above the ground.</li>
                          <li><b>Gold and Spice Souks:</b> Bargain for gold jewelry and aromatic spices in Deira.</li>
                          <li><b>Indoor Skiing at Ski Dubai:</b> A snowy retreat in the middle of the desert.</li>
                          <li><b>Global Village:</b> A cultural extravaganza featuring pavilions from around the world.
                          </li>
                        </ul>
                        <h2>Dubai Travel Tips</h2>
                        <ul>
                          <li><b>Currency:</b> UAE Dirham (AED).</li>
                          <li><b>Language:</b> Arabic, but English is widely spoken.</li>
                          <li><b>Dress Code:</b> Respect the local culture by dressing modestly, especially in public areas.</li>
                          <li><b>Transportation:</b> Use the efficient Dubai Metro or taxis for convenience.</li>
                        </ul>
                        <h2>Frequently Asked Questions(FAQ's)</h2>
                        <Faq faqData={faqData}/>
                        </Fade>
                    </div>
                </div>
            </div>
                <p className='dubai-destination-head mb-3 mt-5'>View Our Dubai Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {himachalPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </div>
    );
}

export default DestinationDubai;
