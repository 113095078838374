import React, { useState, useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";
import DummyForm from "../DummyForm";

const WhatsAppButton = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isFormClosedManually, setIsFormClosedManually] = useState(false);

  const closeForm = () => {
    setIsFormOpen(false);
    setIsFormClosedManually(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500 && !hasScrolled && !isFormClosedManually) {
        setIsFormOpen(true);
        setHasScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled, isFormClosedManually]);

  const handleClick = () => {
    const phoneNumber = "917483129148"; // Ensure this is in the correct format
    const message = "Hi"; // Message you want to send
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`; // Properly encode the message
    window.open(url, "_blank"); // Open in a new tab
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "30px",
        right: "10px",
        zIndex: 999,
      }}
    >
      <button
        aria-label="Send message"
        onClick={handleClick}
        style={{
          backgroundColor: "#25D366",
          border: "none",
          height: "50px",
          borderRadius: "25px",
          padding: "0 20px",
          color: "white",
          fontSize: "16px",
          cursor: "pointer",
          boxShadow: "0 4px 8px rgba(0,0,0,0.8)",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "5px",
          fontFamily: "Poppins",
          fontWeight: "bold",
        }}
      >
        <FaWhatsapp size={24} />
        <span>Enquire Now</span>
      </button>

      {/* Popup Form */}
      {isFormOpen && (
        <div>
          <DummyForm onClose={closeForm} />
        </div>
      )}
    </div>
  );
};

export default WhatsAppButton;
