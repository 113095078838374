import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {Fade} from 'react-awesome-reveal';
import Callback from '../Callback';
import './index.css';
import Faq from '../Faq';

const faqData = [
    {
      question: "1. What is the best time to visit Singapore?",
      answer: `Singapore is ideal to visit year-round, but February to April offers the best weather. Plan around major events like the Great Singapore Sale in June or the Formula 1 Grand Prix in September.`
    },
    {
      question: "2. Do I need a visa to travel to Singapore?",
      answer: `Many nationalities, including those from the USA, UK, EU, Australia, and India, can enter visa-free for short stays. Check with the Singapore Immigration Authority for specific requirements.`
    },
    {
      question: "3. What are the must-visit attractions in Singapore?",
      answer: `Top attractions include:<br>
               - <strong>Gardens by the Bay</strong>: Iconic Supertree Grove and Cloud Forest.<br>
               - <strong>Sentosa Island</strong>: Universal Studios and beaches.<br>
               - <strong>Singapore Zoo</strong>: One of the best rainforest zoos in the world.<br>
               - <strong>Marina Bay Sands</strong>: SkyPark and infinity pool.`
    },
    {
      question: "4. What is the local currency, and are credit cards accepted?",
      answer: `The currency is the Singapore Dollar (SGD). Credit cards are widely accepted, but it's useful to carry cash for street markets and hawker centers.`
    },
    {
      question: "5. Is Singapore child-friendly?",
      answer: `Yes, Singapore is highly family-friendly. Attractions like Sentosa, the Night Safari, and the Science Centre are perfect for kids.`
    },
    {
      question: "6. What should I pack for a trip to Singapore?",
      answer: `Essentials include:<br>
               - Lightweight clothing suitable for tropical weather.<br>
               - Comfortable walking shoes for city exploration.<br>
               - A small umbrella or raincoat for sudden downpours.`
    },
    {
      question: "7. Can I drink tap water in Singapore?",
      answer: `Yes, tap water in Singapore is safe to drink and meets World Health Organization standards.`
    },
    {
      question: "8. How do I get around in Singapore?",
      answer: `The MRT system is the most convenient and affordable way to get around. Taxis, Grab (ride-hailing app), and buses are also widely available.`
    },
    {
      question: "9. What local dishes should I try?",
      answer: `Must-try dishes include:<br>
               - <strong>Chili Crab</strong>: A local seafood delicacy.<br>
               - <strong>Hainanese Chicken Rice</strong>: Singapore’s national dish.<br>
               - <strong>Laksa</strong>: A spicy noodle soup.<br>
               - <strong>Kaya Toast</strong>: A popular breakfast item.`
    },
    {
      question: "10. Is tipping customary in Singapore?",
      answer: `Tipping is not expected in Singapore. Restaurants and hotels typically include a 10% service charge in the bill.`
    }
  ];
  
  

const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/singapore%2Fsin-1.webp?alt=media&token=ae3f83f0-48c5-44c2-b24e-02f813a5112d",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/singapore%2Fsin-2.webp?alt=media&token=cba51adc-ccb0-4a41-9e26-0ba95b89bf91",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/singapore%2Fsin-3.webp?alt=media&token=73dc1737-5555-4374-a126-05e5f5c57162",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/singapore%2Fsin-4.webp?alt=media&token=cfe8967b-ae4c-4138-b625-bb2bea61bf02",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/singapore%2Fsin-5.webp?alt=media&token=dcd3ed8a-97c2-4e05-b1a6-52a2cd17fe06",
]

const DestinationSingapore = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const himachalPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('singapore')
      );

    return (
        <div>
        <HelmetProvider>
        <Helmet>
            <title>Explore Singapore: Ultimate Travel Guide, Attractions, and Tips</title>
            <meta
                name="description"
                content="Plan your perfect trip to Singapore with insider tips on the best attractions, local dishes, visa requirements, weather, and more. Explore now!"
                />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <Callback contactNumber="+91 9141001434"/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 sing-container mt-5'>
                     <Fade>
                        <h1>Explore the Best of Singapore – Your Ultimate Travel Guide</h1>
                        <h2>Why Visit Singapore?</h2>
                        <p>Singapore is a dynamic city-state known for its futuristic skyline, rich cultural diversity, and world-class attractions. Whether you're exploring lush gardens, savoring delicious street food, or shopping on Orchard Road, Singapore offers an unforgettable mix of tradition and modernity.</p>
                        <h2>Best Time to Visit</h2>
                        <p>Singapore is a year-round destination, thanks to its tropical climate. However, the best months to visit are February to April, when the weather is more pleasant, and events like the Singapore Food Festival and Chingay Parade take place.</p>
                        <h2>Visa Formalities</h2>
                        <p>Most nationalities, including those from the USA, UK, EU, Australia, and India, can enter Singapore visa-free for short stays. For detailed visa information, visit the <a href='https://www.ica.gov.sg/' target='__blank'>Singapore Immigration and Checkpoints Authority website</a>.</p>
                        <h2>Top Destinations in Singapore</h2>
                        <ul>
                            <li><b>1. Marina Bay Sands</b><br/>Marvel at the iconic Marina Bay Sands, featuring a rooftop infinity pool, luxury shopping, and the stunning SkyPark.</li>
                            <li><b>2. Gardens by the Bay</b><br/>Experience the futuristic Supertree Grove, Cloud Forest, and Flower Dome in this award-winning garden.</li>
                            <li><b>3. Sentosa Island</b><br/>A paradise for families and thrill-seekers, Sentosa features Universal Studios, Adventure Cove Waterpark, and sandy beaches.</li>
                            <li><b>4. Chinatown</b><br/>Discover vibrant street markets, heritage temples, and authentic Chinese cuisine in this cultural hub.</li>
                            <li><b>5. Singapore Zoo & Night Safari</b><br/>Perfect for wildlife enthusiasts, these attractions offer immersive encounters with exotic animals.</li>
                        </ul>
                        <h2>Top Activities</h2>
                        <ul>
                            <li><b>City Skyline Views:</b> Enjoy panoramic views from the Singapore Flyer or Marina Bay Sands SkyPark.</li>
                            <li><b>Shopping:</b> Explore Orchard Road for high-end shopping or Haji Lane for indie boutiques.</li>
                            <li><b>Culinary Adventures:</b> Relish hawker delights like chili crab, Hainanese chicken rice, and laksa.</li>
                            <li><b>Cultural Trails:</b> Visit Little India, Chinatown, and Kampong Glam for a rich cultural experience.</li>
                            <li><b>Adventure & Relaxation:</b> Zipline at Sentosa or unwind at East Coast Park.</li>
                        </ul>
                        <h2>Travel Tips</h2>
                        <ul>
                            <li><b>Currency:</b> Singapore Dollar (SGD)</li>
                            <li><b>Language:</b> English is widely spoken, alongside Mandarin, Malay, and Tamil.</li>
                            <li><b>Transportation:</b> The MRT (Mass Rapid Transit) system is efficient, and taxis and buses are easily available.</li>
                            <li><b>Local Delights:</b> Try satay, nasi lemak, and kaya toast at hawker centers.</li>
                        </ul>
                        <h2>Plan Your Trip</h2>
                        <p>Our tailored packages cater to every traveler:</p>
                        <ul>
                            <li><b>Singapore City Explorer(2N/3D)</b>Discover Marina Bay Sands, Gardens by the Bay, and Chinatown.</li>
                            <li><b>Singapore Cultural Escape(3N/4D)</b>Immerse yourself in the heritage of Little India, Kampong Glam, and Chinatown.</li>
                            <li><b>Singapore Family Fun Adventure(4N/5D)</b>Explore Sentosa Island, Universal Studios, and the Singapore Zoo.</li>
                        </ul>
                        <p>Customize your experience with our flexible itineraries and enjoy exclusive deals and guided tours.</p>
                        <p>Start planning your Singapore adventure with Travel Unbounded for a seamless and memorable journey.</p>
                        <h2>Frequently Asked Questions(FAQ's)</h2>
                        <Faq faqData={faqData}/>
                        </Fade>
                    </div>
                </div>
            </div>
                <p className='sing-destination-head mb-3 mt-5'>View Our Singapore Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {himachalPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </div>
    );
}

export default DestinationSingapore;
