import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Callback from '../Callback';
import {Fade} from 'react-awesome-reveal';
import './index.css';
import Faq from '../Faq';


const faqData = [
  {
    question: "What are the must-visit tourist destinations in Vietnam?",
    answer: "Vietnam offers a variety of stunning destinations, including Halong Bay for its limestone karsts, Hoi An Ancient Town for its preserved culture, Hanoi for its historic landmarks, and Ho Chi Minh City for its vibrant energy. Don’t miss the terraced rice fields in Sapa and the beautiful beaches of Nha Trang."
  },
  {
    question: "Do I need a visa to travel to Vietnam?",
    answer: "Yes, most travelers need a visa to visit Vietnam. However, citizens of certain countries enjoy visa exemptions. You can apply for an e-Visa online or obtain a visa on arrival with prior approval."
  },
  {
    question: "What is the best time of year to visit Vietnam?",
    answer: "The ideal time depends on the region you plan to visit. Generally, March to April and September to November offer pleasant weather with fewer crowds, making it the best time to explore the country."
  },
  {
    question: "How many days are ideal for a Vietnam tour?",
    answer: "A 7 to 10-day trip is perfect to cover Vietnam’s highlights, including Hanoi, Halong Bay, Hoi An, and Ho Chi Minh City. If you want to explore more or relax at beaches, a 14-day itinerary is ideal."
  },
  {
    question: "What are the top things to do in Vietnam for first-time travelers?",
    answer: "Explore the streets of Hanoi, cruise in Halong Bay, enjoy the lantern-lit nights in Hoi An, visit the Cu Chi Tunnels, and indulge in authentic Vietnamese street food like Pho and Banh Mi."
  },
  {
    question: "Is Vietnam a family-friendly travel destination?",
    answer: "Yes, Vietnam is very family-friendly, offering activities like beach holidays, nature treks, cultural experiences, and historical tours that cater to all age groups."
  },
  {
    question: "What are some budget travel tips for exploring Vietnam?",
    answer: "Travelers can save money by choosing local transportation like buses or trains, staying in budget-friendly accommodations, and eating at local street food markets, which offer delicious yet inexpensive meals."
  },
  {
    question: "Which are the best places in Vietnam for nature lovers?",
    answer: "Nature enthusiasts will enjoy Phong Nha-Ke Bang National Park, the rice terraces of Sapa, the beaches of Da Nang, and the serene countryside of the Mekong Delta."
  },
  {
    question: "Are there any luxury travel options available in Vietnam?",
    answer: "Absolutely! Vietnam has world-class luxury resorts, private cruises in Halong Bay, and exclusive cultural tours in cities like Hue and Da Nang."
  },
  {
    question: "What are the best cities to visit in Vietnam for food enthusiasts?",
    answer: "Food lovers should explore Hanoi, Ho Chi Minh City, and Hue, where they can try authentic Vietnamese dishes such as Pho, Bun Cha, and Banh Xeo."
  },
  {
    question: "Is Vietnam safe for solo travelers?",
    answer: "Yes, Vietnam is considered one of the safest countries for solo travelers. People are friendly, and crime rates are low. Always take standard precautions, especially at night."
  },
  {
    question: "What currency is used in Vietnam, and where can I exchange money?",
    answer: "The currency used is the Vietnamese Dong (VND). You can exchange money at airports, banks, or authorized exchange counters in major cities."
  }
];


const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/vietnam%2Fv-1.webp?alt=media&token=91070055-73d5-4888-94b1-f96146c17d0a",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/vietnam%2Fv-2.webp?alt=media&token=47b0213e-906f-4058-9af3-378b10723783",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/vietnam%2Fv-3.webp?alt=media&token=ed521aa1-5221-4e36-a5f3-6e4d24e955bf",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/vietnam%2Fv-5.webp?alt=media&token=dafe32d8-cbdb-453b-b595-7a47d11564ad"
]

const DestinationVietnam = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const keralaPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('vietnam')
      );
    
    return (
        <>
         <HelmetProvider>
         <Helmet>
            <title>Vietnam Tour packages Luxury stays - Book your dream trip now</title>
            <meta
                name="description"
                content="Best vietnam travel package. Travelunbouned provide you the best and customised
                vietnam tour deals.Book now for your dream vietnam travel"
            />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <Callback contactNumber="+91 9845090014" />
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 vietnam-container mt-5'>
                      <Fade>
                     <h1>Discover the Wonders of Vietnam: A Complete Guide to the "Land of the Ascending Dragon"</h1>
                     <p>Vietnam is an enchanting country in Southeast Asia, known for its diverse landscapes, rich cultural heritage, and vibrant local traditions. Whether you're looking for a peaceful escape or an adventurous journey, Vietnam offers a unique blend of natural beauty, modern conveniences, and ancient history, making it an ideal destination for travelers and a fascinating place to explore.</p>
                     <h2>Geography of Vietnam: A Blend of Natural Beauty and History</h2>
                     <p>Vietnam’s geography is defined by towering mountains, expansive rice terraces, and a long coastline. With the Annamite Range in the west and the South China Sea to the east, the country is a mix of highlands, dense forests, and pristine beaches, making it one of the most scenic and diverse regions in Southeast Asia.</p>
                     <h2>Airports in Vietnam: Gateway to Global Exploration</h2>
                     <p>Vietnam is well-connected to the rest of the world, with several international airports, making it convenient for both domestic and international travelers to explore this beautiful country.</p>
                     <ul>
                      <li><b>Noi Bai International Airport (HAN):</b><br/>Located in the capital city of Hanoi, this is one of the busiest airports in Vietnam, serving northern Vietnam. It connects Hanoi with major international destinations and is the main gateway for exploring the northern region.</li>
                      <li><b>Tan Son Nhat International Airport (SGN):</b><br/>Situated in Ho Chi Minh City, Tan Son Nhat is the largest airport in the country. It offers flights to numerous global destinations and provides easy access to the southern regions of Vietnam, including the Mekong Delta.</li>
                      </ul>
                      <h2>Climate of Vietnam: A Tropical Adventure</h2>
                      <p>Vietnam has a tropical climate with a distinct monsoon season from May to October, bringing abundant rainfall that supports the country’s lush vegetation. The weather varies by region, with the northern part experiencing cooler winters, while the southern regions remain warm year-round, making it an ideal destination for nature lovers and adventurers.</p>
                      <h2>Culture of Vietnam: A Fusion of Tradition and Modernity</h2>
                      <p>Vietnam's rich cultural heritage reflects centuries-old traditions influenced by various dynasties and cultures. The country is famous for its traditional festivals such as Tet (Lunar New Year) and Mid-Autumn Festival, and its vibrant art forms, including water puppetry and traditional music. Vietnamese cuisine, handicrafts, and ancient architecture add to the country’s cultural richness, offering visitors an immersive experience.</p>
                      <h2>Visa Information for Foreign Nationals</h2>
                      <p>Foreign visitors should check the specific <a
                  href="https://evisa.xuatnhapcanh.gov.vn/trang-chu-ttdt"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 visa requirements
                </a> for their country before planning their trip. Vietnam offers several visa options, including e-visas, making travel arrangements simple and convenient.</p>
                  <h2>Unique Vietnam Experiences: Top Destinations to Explore</h2>
                  <p>Vietnam offers a wide range of experiences for all types of travelers. Here are some must-visit destinations that capture the essence of this remarkable country:</p>
                  <h3>1. Cruise the Majestic Waters of Vietnam</h3>
                  <ul>
                    <li><b>Halong Bay:</b> Known for its emerald waters and thousands of limestone islands, Halong Bay is a UNESCO World Heritage Site. Visitors can enjoy boat cruises through the bay, exploring caves and floating villages amidst stunning natural scenery.</li>
                    <li><b>Mekong Delta:</b> The Mekong Delta, in southern Vietnam, is famous for its intricate network of rivers, swamps, and floating markets. A boat tour through the delta allows travelers to experience traditional Vietnamese life and the lush tropical landscape.</li>
                  </ul>
                  <h3>2. Explore Vietnam’s Scenic Mountains</h3>
                  <ul>
                    <li><b>Sapa:</b> Nestled in the Hoang Lien Son mountains, Sapa is renowned for its terraced rice fields and ethnic hill tribes. Visitors can trek through the beautiful valleys and enjoy the cool climate and breathtaking views.</li>
                    <li><b>Ha Giang:</b> For those looking for a more remote adventure, Ha Giang offers stunning mountain passes, untouched villages, and some of the most dramatic landscapes in Vietnam. It’s perfect for motorbike tours and photography enthusiasts.</li>
                  </ul>
                  <h3>3. Unwind on Vietnam’s Pristine Beaches</h3>
                  <ul>
                    <li><b>Phu Quoc Island:</b> Located off the southern coast, Phu Quoc Island is a tropical paradise known for its white sandy beaches and clear waters. It’s a perfect destination for beachgoers, offering opportunities for snorkeling, diving, and relaxation.</li>
                    <li><b>Nha Trang:</b> A popular coastal city, Nha Trang is famous for its beautiful beaches, vibrant nightlife, and water sports. Visitors can enjoy sunbathing, swimming, and exploring nearby islands by boat.</li>
                    <li><b>Da Nang:</b> Da Nang boasts some of the most famous beaches in Vietnam, including My Khe Beach. The city also serves as a gateway to the ancient town of Hoi An and the Marble Mountains, making it a great spot for both relaxation and exploration.</li>
                  </ul>
                  <h3>4. Discover Vietnam’s Wildlife Sanctuaries</h3>
                  <ul>
                    <li><b>Cuc Phuong National Park:</b> Vietnam’s oldest national park, Cuc Phuong is home to a wide range of wildlife, including endangered primates. Visitors can explore the dense forests, ancient caves, and learn about conservation efforts in the area.</li>
                    <li><b>Phong Nha-Ke Bang National Park:</b> A UNESCO World Heritage Site, this park is known for its impressive cave systems, including the world’s largest cave, Son Doong. Visitors can trek through the jungle and marvel at the underground rivers and stalactites.</li>
                  </ul>
                  <h3>5. Visit Cultural and Historical Landmarks</h3>
                  <ul>
                    <li><b>Hoi An Ancient Town:</b> A UNESCO World Heritage Site, Hoi An is a beautifully preserved trading port with a rich history. The town is famous for its lantern-lit streets, ancient temples, and charming architecture, offering a glimpse into Vietnam’s past.</li>
                    <li><b>Hue Imperial City:</b> The former capital of Vietnam, Hue is home to the Imperial City, a sprawling complex of palaces, temples, and tombs from the Nguyen dynasty. It’s a must-visit for history enthusiasts and those interested in Vietnam’s royal heritage.</li>
                  </ul>
                  <h3>6. Experience Vietnamese Wellness and Relaxation</h3>
                  <ul>
                    <li><b>Mui Ne:</b> A coastal town known for its sand dunes and palm-lined beaches, Mui Ne also offers world-class resorts where visitors can indulge in spa treatments and wellness programs, including traditional Vietnamese therapies.</li>
                    <li><b>Ninh Van Bay:</b> A remote and luxurious destination, Ninh Van Bay is the perfect place for a peaceful retreat. Visitors can enjoy wellness retreats, yoga sessions, and holistic therapies amidst the beauty of the bay.</li>
                  </ul>
                  <h3>7. Adventure Activities in Vietnam</h3>
                  <ul>
                    <li><b>Fansipan:</b> Known as the "Roof of Indochina," Fansipan is the highest peak in Vietnam. Adventurers can take a cable car ride or trek to the summit for stunning panoramic views.</li>
                    <li><b>Phong Nha Caves:</b> The caves in Phong Nha-Ke Bang National Park are perfect for thrill-seekers who want to explore underground rivers, climb through giant caverns, and experience the awe-inspiring beauty of Vietnam’s natural wonders.</li>
                  </ul>
                  <h3>8. Shop for Local Crafts and Goods</h3>
                  <ul>
                    <li><b>Hanoi’s Old Quarter:</b> Hanoi is a bustling hub of traditional markets where visitors can shop for souvenirs, including silk, lacquerware, and handicrafts. The Old Quarter is famous for its narrow streets lined with shops selling everything from food to fine art.</li>
                    <li><b>Ben Thanh Market:</b> Located in Ho Chi Minh City, this market is a great place to experience local culture. Shoppers can find a variety of Vietnamese goods, including spices, clothing, and handcrafted items.</li>
                  </ul>
                  <p>With its vibrant culture, stunning landscapes, and diverse attractions, Vietnam is a must-visit destination for travelers seeking adventure, relaxation, and unforgettable experiences.</p>
                  </Fade>
                  <h2>Frequently Asked Questions(FAQ's)</h2>
                  <Faq faqData={faqData} />
                  </div>
                </div>
            </div>
                <p className='vietnam-destination-head mb-3 mt-5'>View Our Vietnam Travel Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {keralaPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <p className="title-list mb-3">{pkg.destinationHead}</p>
                            <p className="title-dates-list bold mb-3">{pkg.noOfDays}</p>
                            <p className="title-rate-list bold mb-3">{pkg.cost}</p>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </>
    );
}

export default DestinationVietnam;
